import React, { useEffect, useState } from "react";
import KeyLayout from "../Components/KeyFeature/KeyLayout";
import { Icardprops } from "../Components/BenefitsCard/OurValueCard";
import keycard1 from "./assets/keysol4card1.jpg";
import keycard2 from "./assets/keysol4card2.jpg";
import keycard3 from "./assets/keysol4card3.jpg";
import keycard4 from "./assets/keysol4card4.jpg";
import keycard5 from "./assets/keysol4card5.jpg";
import BenefitLayout from "../Components/BenefitsCard/BenefitLayout";
import benefitcard1 from "./assets/benefitsol4card1.jpg";
import benefitcard2 from "./assets/benefitsol4card2.jpg";
import benefitcard3 from "./assets/benefitsol4card3.jpg";
import benefitcard4 from "./assets/benefitsol4card4.jpg";
import benefitcard5 from "./assets/benefitsol4card5.jpg";
import benefitcard6 from "./assets/benefitsol4card6.jpg";
import LogoBanner from "../../../Common/LogoBanner/LogoBanner";
import Banner from "../../../Common/Banner/Banner";
import TextImgBanner from "../Components/TextImgBanner/TextImgBanner";
import bannerbelowimg from "./assets/bannerbelowimgsol4.jpg";
import bannerImg from "../Assets/solnBannerImg.svg";

const KeyFeatureData: Icardprops[] = [
  {
    heading: "In-app discussion board & meetings",
    subheadings:
      "Teams can identify risks together and brainstorm in meetings to mitigate them, ensuring transparency, building consensus, and establishing collective ownership. Discussion boards also serve as a central repository for decisions and justifications related to AI governance. This documented record simplifies audits and demonstrates a commitment to responsible AI practices.",
    image: keycard1,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "Actionable dashboard and notifications",
    subheadings:
      "Dashboards provide a centralized view of AI risks enabling continuous monitoring and easy identification of emerging issues. Parallelly, notifications inform users of risk, provide actionable insights, and prompt them to take action. These two features effectively improve the effectiveness of AI governance and proactively manage risks.",
    image: keycard2,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "Automated checks and controls",
    subheadings:
      "Automated checks and controls promote continuous monitoring and early detection of issues like policy violations or model performance. They also help ensure adherence to regulations and mitigate the risk of non-compliance. Additionally, implement them to mitigate bias and fairness risks and ensure explainability.",
    image: keycard3,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "Link business outcomes with explainable algorithms",
    subheadings:
      "Our solution integrates explainable AI (XAI) techniques to demystify how AI models arrive at decisions and help you understand how the model contributes to achieving your business goals. With this understanding, you can proactively identify risks, adjust the model to optimize results, and mitigate risk.",
    image: keycard4,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "AI Governance",
    subheadings:
      "It ensures high-quality data for training through defined standards and access controls. It tracks data lineage to understand its flow and identify potential biases. This focus on data quality improves trust in your AI's reliability and compliance with data security regulations.",
    image: keycard5,
    cardcontainercss: { height: "352px" },
  },
];

const benefitcardData: Icardprops[] = [
  {
    heading: "Build Trustworthy AI",
    subheadings:
      "Mitigate bias, ensure fairness, and promote transparency with automated bias detection and mitigation, along with real-time dashboards and explainability.",
    image: benefitcard1,
    cardcontainercss: { height: "clamp(8.0625rem, 7.4932rem + 1.1844vw, 8.5rem)" },
  },
  {
    heading: "Proactive Risk Management",
    subheadings:
      "Features like upstream and downstream systems monitoring, actionable dashboards and notifications, and automated checks help identify and address potential risks before they become problems.",
    image: benefitcard2,
    cardcontainercss: { height: "clamp(8.0625rem, 7.4932rem + 1.1844vw, 8.5rem)" },
  },
  {
    heading: "Streamlined Governance",
    subheadings:
      "Simplify compliance with regulations and manage AI initiatives efficiently with our centralized data & risk management platform with customizable governance frameworks.",
    image: benefitcard3,
    cardcontainercss: { height: "clamp(8.0625rem, 7.4932rem + 1.1844vw, 8.5rem)" },
  },
  {
    heading: "Improved Transparency and Explainability",
    subheadings:
      "Dashboards, discussion boards, and linking business outcomes with explainable algorithms help foster trust.",
    image: benefitcard4,
    cardcontainercss: { height: "clamp(8.0625rem, 7.4932rem + 1.1844vw, 8.5rem)" },
  },
  {
    heading: "Collaboration and Communication",
    subheadings:
      "Collaborative environment with a discussion board breaks down silos and ensures that everyone's on the same page.",
    image: benefitcard5,
    cardcontainercss: { height: "clamp(8.0625rem, 7.4932rem + 1.1844vw, 8.5rem)" },
  },
  {
    heading: "Scalable",
    subheadings:
      "Our customizable governance frameworks grow with your AI needs and adapt to evolving regulations.",
    image: benefitcard6,
    cardcontainercss: { height: "clamp(8.0625rem, 7.4932rem + 1.1844vw, 8.5rem)" },
  },
];
const bannerData = {
  title:
    "Achieve ethical, trustworthy AI with EvoNext’s AI governance and risk management solution",
  subtitle:
    "Mitigate risks and build trust in your AI initiatives with EvoNext’s AI Governance Solution. \n\nOur AI Governance Solution provides a comprehensive, secure framework that safeguards your AI initiatives from data to deployment. Our AI governance specialists guide you through every step, fostering a collaborative environment where data scientists, developers, and business leaders can work together to build trust, use data-driven insights to make decisions, and achieve ethical AI. \n\nDiscover how our suite of Enterprise Risk Management (ERM) solutions can manage end-to-end risk, including Governance, Risk, Operational Risk Change Management, Compliance, and more, for responsible AI and safe and secure operations of AI systems.",
  image1: bannerImg,
  imgcss: { right: "0" },
  buttontext: "Request Demo",
  button: true,
  buttoncss: "requestdemobtn",
  textcss: { width: "clamp(18.5rem, -24.1138rem + 88.6633vw, 51.25rem)" },
  imgClass:"right"
};
const imgTextBannerData = {
  mainContainerStyles: { width: "100%", height: "clamp(29.3125rem, 20.8086rem + 13.2743vw, 32.125rem)" },
  textContainerStyles: { width: "clamp(29.75rem, 7.8289rem + 34.2183vw, 37rem)", height: "clamp(25.5625rem, 23.4838rem + 3.2448vw, 26.25rem)" },
  heading:
    "Customizable, collaborative AI governance and risk management solution",
  img: bannerbelowimg,
  imgcss:{
    width: "clamp(29.0625rem, -7.5986rem + 57.2271vw, 41.1875rem)",
    height: "clamp(22.6875rem, -5.8477rem + 44.5428vw, 32.125rem)",
  }
};
const imgTextBannerData360 = {
  mainContainerStyles: {  width: "328px",
    marginLeft:"auto",
    marginRight:"auto", height: "719px" },
  textContainerStyles: { width: "328px", height: "398px" },
  heading:
    "Customizable, collaborative AI governance and risk management solution",
  img: bannerbelowimg,
  imgcss:{
    width: "clamp(29.0625rem, -7.5986rem + 57.2271vw, 41.1875rem)",
    height: "clamp(22.6875rem, -5.8477rem + 44.5428vw, 32.125rem)",
  }
};

const ImgTextBanner = () => {
  return (
    <div>
      We all know AI is powerful, but building it responsibly requires a robust
      foundation. Our AI Governance and Risk Management Solution acts as that foundation.
      <br />
      <br />
      It
      offers a central platform for managing risk assessments, designing
      governance policies, and monitoring across upstream and downstream
      systems. This unified approach tackles AI challenges with features like AI
      observability tool integration and model explainability. Additionally,
      features like real-time dashboards foster trust by demystifying AI
      decisions, while continuous monitoring helps manage risk effectively.
      Collaboration tools keep everyone informed and working together and ensure
      ethical adoption. And customizable governance frameworks help align with
      your business goals. <br />
      <br />
      Tailor your governance approach and achieve responsible, ethical AI.
    </div>
  );
};
const AiGovernance = () => {
  const [textimagebannerdata, setCardsData] = useState( window.innerWidth<=800 ? imgTextBannerData360 : imgTextBannerData);
  useEffect(() => {
    const handleResize = () => {
      const newData =
      window.innerWidth<=800 ? imgTextBannerData360 : imgTextBannerData;
      setCardsData(newData);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section>
      <div className="aboutusbannerimg">
        <Banner data={bannerData} />
      </div>
      <div className="adjustment-container">
        <TextImgBanner
          mainContainerStyles={textimagebannerdata.mainContainerStyles}
          textContainerStyles={textimagebannerdata.textContainerStyles}
          heading={textimagebannerdata.heading}
          img={textimagebannerdata.img}
          imgcss={textimagebannerdata.imgcss}
        >
          <ImgTextBanner />
        </TextImgBanner>
      </div>
      <KeyLayout data={KeyFeatureData} />
      <BenefitLayout data={benefitcardData} />
      <div className="logo-bannerabout">
        <LogoBanner
          text={
            "Simplify AI compliance and build trustworthy AI with our AI governance and risk management solution."
          }
        />
      </div>
    </section>
  );
};

export default AiGovernance;
