import { useNavigate } from "react-router-dom";
import "./Banner.css";
import { useScrollContext } from "../../ScrollContext";
interface bannerprops {
  data: {
    title: string;
    subtitle: string;
    image1: string;
    image2?: string;
    button?: boolean;
    imgcss?: {};
    buttontext?: string;
    buttoncss?: string;
    textcss?: {};
    titlecss?: {};
    layOutCss?: {};
    imgClass?: string;
    img2css?:{};
  };
}
const Banner = ({ data }: bannerprops) => {
  const {
    title,
    subtitle,
    image1,
    image2,
    button,
    imgcss,
    buttontext,
    buttoncss,
    textcss,
    titlecss,
    layOutCss,
    imgClass,
    img2css
  } = data;
  console.log(button);
  const navigate = useNavigate();
  const { scrollToForm } = useScrollContext();
  return (
    <section className="contactUsBanner" style={layOutCss}>
      {/* <div style={imgcss} className="mapImg"> */}
      <img src={image1} alt="" className={`mapImg ${imgClass}`} />
      {/* </div> */}
      <div className="contactUsBanner-itemContainer">
        <div className="contactImg">
          <img style={img2css} src={image2} alt="" className="contacticon-img" />
        </div>
        <div style={textcss} className="contactUs-Text">
          <div style={titlecss} className="contactBannerTitle">
            {title}
          </div>
          <div className="contactBannerSubTitle">{subtitle}</div>
          {button && (
            <button
            className={`getintouch ${buttoncss}`}
             
              onClick={
                buttontext
                  ? scrollToForm
                  : () => {
                      navigate("/contact");
                    }
              }
            >
              {buttontext ? buttontext : "Get in Touch"}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};
export default Banner;
