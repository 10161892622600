import React from "react";
import { NavLink } from "react-router-dom";

const SolutionDropdown = () => {
  const navLinks = [
    {
      name: "Operational Risk Change Management",
      link: "/solutions/operationalrisk",
    },
    {
      name: "Buisness Continuity Management (BCM)",
      link: "/solutions/businesscontinuity",
    },
    { name: "Outsourcing Risk Management", link: "/solutions/riskmanagement" },
    {
      name: "AI Governance and Risk Management",
      link: "/solutions/aigovernance",
    },
    {
      name: "Process Risks and Controls Management",
      link: "/solutions/processrisks",
    },
  ];
  return (
    <section className="solution-nav-container">
      {navLinks.map((item, i) => (
        <NavLink to={item.link} className="solution-nav">
          {item.name}{" "}
        </NavLink>
      ))}
    </section>
  );
};

export default SolutionDropdown;
