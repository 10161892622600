import "./OurValueCard.css";
export interface Icardprops {
  heading: string;
  subheadings: string;
  image: string;
  cardcontainercss?: {};
}
const OurValueCard = ({ data }: { data: Icardprops }) => {
  const { heading, subheadings, image, cardcontainercss } = data;

  return (
    <div style={cardcontainercss} className="valuecardContainer">
      <img src={image} alt="" className="valuecardimage"></img>
      <div className="valuetext">
        <div className="valuecardheading">{heading}</div>
        <div className="valuesubcontent">{subheadings}</div>
      </div>
    </div>
  );
};
export default OurValueCard;
