import React from 'react'
import './ChooseEvoNext.css';
import Choose from "./assets/HomePageBottomBaenner.svg";

function ChooseEvoNext() {
  return (
    <div className='choose-evonext-main-container'>
        <div className='choose-evonext-content'>Choose <span style={{color: "#FF7A00"}}>EvoNext</span> for streamlined risk management and ultimate business confidence</div>
        <img src={Choose} className='choose-evonext-banner'/>
    </div>
  )
}

export default ChooseEvoNext