import "./CustomPopUp.css";
import React, { ComponentType, ReactNode, CSSProperties } from "react";

interface ModalProps {
  open: boolean;
  onCloseFunction: () => void;
  children: ReactNode;
  modalBoxStyle?: CSSProperties;
}

const CustomPopUp: React.FC<ModalProps> = ({
  open,
  onCloseFunction,
  modalBoxStyle,
  children,
}) => {
  const handleChildClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Stop event propagation
  };
  if (!open) return null; // or render a closed modal
  return (
    <>
      <div className={"modalContainer"} onClick={onCloseFunction}>
        <div
          onClick={handleChildClick}
          className={"modalChild"}
          style={modalBoxStyle}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default CustomPopUp;
