import { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SlideCard.css";
import CarouselCard from "./carouselCard/CarouselCard";

const cardData = [
  {
    cardheading: "Solution Architect",
    Caedcontain:
      "Are you the expert we need to effectively lead teams developing new web applications for a varied range of international clients? Do you have the vision required to solve challenging problems and produce practical solutions? We want to hear from the best solution architects around – is that you?",
  },
  {
    cardheading: "Designer",
    Caedcontain:
      "Have you got an eye for design? Do you love creating attractive yet functional websites that enhance the user experience? And are you looking to work with international businesses demanding only the best from their software designers? We’re always looking for talented designers with a creative spark to join our team.",
  },
  {
    cardheading: "QA Engineer",
    Caedcontain:
      "If you’ve got an unflinching desire for perfection and an unerring need to make the best even better, you could be the QA engineer we’re looking for. Join us to work on a wide range of projects, designing testing mechanisms to ensure only the highest quality software is delivered to our many demanding clients.",
  },
  {
    cardheading: "Data Engineer",
    Caedcontain:
      "Are you an experienced data expert with a desire to work with other top talents? Do you know the ins and outs of SQL? And do you love working with data architecture, data systems, and data processing? If so, you’re exactly the type of data engineer we’re looking for, so send us your CV today.",
  },
  {
    cardheading: "Project Manager",
    Caedcontain:
      "Multi-faceted software development projects require strong leadership and organisation from skilled project managers. If you’ve got experience managing the scope, quality, and schedule of complex software development projects, you’re exactly the type of project manager we need, so get in touch today.",
  },
  {
    cardheading: "Software Engineer",
    Caedcontain:
      "We’re always looking for talented software engineers to work on a wide variety of projects, with clients from around the world. If you love nothing better than designing top-notch software for businesses in a diverse range of industries and niches, we’re looking for you, so get in touch with us today!",
  },
];

function SlideCard() {
  let sliderRef = useRef(null);

  const next = () => {
    //@ts-ignore
    sliderRef.slickNext();
  };
  const previous = () => {
    //@ts-ignore
    sliderRef.slickPrev();
  };

  const [noOfSlides, setNoOfSlides] = useState(window.innerWidth < 730 ? 1 : 3);

  useEffect(() => {
    const slideSetter = () => {
      if (window.innerWidth < 730) {
        setNoOfSlides(1);
      } else {
        setNoOfSlides(3);
      }
    };

    window.addEventListener("resize", slideSetter);

    return () => {
      window.removeEventListener("resize", slideSetter);
    };
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: noOfSlides,
    slidesToScroll: 1,
    responsive: [
      // {
      //   breakpoint: 1328,
      //   settings: {
      //     dots: false,
      //     infinite: true,
      //     speed: 500,
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //   },
      // },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     dots: false,
      //     infinite: true,
      //     speed: 500,
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  return (
    <>
      <div className="SliderBox">
        <Slider
          {...settings}
          ref={(slider: any) => {
            sliderRef = slider;
          }}
          className="Slider"
        >
          {cardData.map((ele, index) => {
            return (
              <CarouselCard
                CardHeading={ele.cardheading}
                CardContain={ele.Caedcontain}
                key={index}
              />
            );
          })}
        </Slider>
      </div>
      <div className="arrowButton">
        <button
          className="button "
          onClick={previous}
          style={{ display: "flex" }}
        >
          <svg
            className="arrow-svg"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1002 16L18.2668 12.8334C18.5113 12.5889 18.6335 12.2834 18.6335 11.9167C18.6335 11.55 18.5113 11.2334 18.2668 10.9667C18.0002 10.7 17.6835 10.5667 17.3168 10.5667C16.9502 10.5667 16.6335 10.7 16.3668 10.9667L12.2668 15.0667C12.0002 15.3334 11.8668 15.6445 11.8668 16C11.8668 16.3556 12.0002 16.6667 12.2668 16.9334L16.4002 21.0667C16.6668 21.3334 16.9779 21.4611 17.3335 21.45C17.689 21.4389 18.0002 21.3 18.2668 21.0334C18.5113 20.7667 18.639 20.4556 18.6502 20.1C18.6613 19.7445 18.5335 19.4334 18.2668 19.1667L15.1002 16ZM2.66682 16C2.66682 14.1556 3.01682 12.4222 3.71683 10.8C4.41683 9.1778 5.36683 7.76669 6.56683 6.56669C7.76683 5.36669 9.17794 4.41669 10.8002 3.71669C12.4224 3.01669 14.1557 2.66669 16.0002 2.66669C17.8446 2.66669 19.5779 3.01669 21.2002 3.71669C22.8224 4.41669 24.2335 5.36669 25.4335 6.56669C26.6335 7.76669 27.5835 9.1778 28.2835 10.8C28.9835 12.4222 29.3335 14.1556 29.3335 16C29.3335 17.8445 28.9835 19.5778 28.2835 21.2C27.5835 22.8222 26.6335 24.2334 25.4335 25.4334C24.2335 26.6334 22.8224 27.5834 21.2002 28.2834C19.5779 28.9834 17.8446 29.3334 16.0002 29.3334C14.1557 29.3334 12.4224 28.9834 10.8002 28.2834C9.17794 27.5834 7.76683 26.6334 6.56683 25.4334C5.36683 24.2334 4.41683 22.8222 3.71683 21.2C3.01682 19.5778 2.66682 17.8445 2.66682 16Z"
              fill="#535B62"
            />
          </svg>
        </button>
        <button className="button" onClick={next} style={{ display: "flex" }}>
          <svg
            className="arrow-svg"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.8998 16L13.7332 19.1667C13.4887 19.4111 13.3665 19.7167 13.3665 20.0834C13.3665 20.45 13.4887 20.7667 13.7332 21.0334C13.9998 21.3 14.3165 21.4334 14.6832 21.4334C15.0498 21.4334 15.3665 21.3 15.6332 21.0334L19.7332 16.9334C19.9998 16.6667 20.1332 16.3556 20.1332 16C20.1332 15.6445 19.9998 15.3334 19.7332 15.0667L15.5998 10.9334C15.3332 10.6667 15.0221 10.5389 14.6665 10.55C14.311 10.5611 13.9998 10.7 13.7332 10.9667C13.4887 11.2334 13.361 11.5445 13.3498 11.9C13.3387 12.2556 13.4665 12.5667 13.7332 12.8334L16.8998 16ZM29.3332 16C29.3332 17.8445 28.9832 19.5778 28.2832 21.2C27.5832 22.8223 26.6332 24.2334 25.4332 25.4334C24.2332 26.6334 22.8221 27.5834 21.1998 28.2834C19.5776 28.9834 17.8443 29.3334 15.9998 29.3334C14.1554 29.3334 12.4221 28.9834 10.7998 28.2834C9.17762 27.5834 7.7665 26.6334 6.5665 25.4334C5.3665 24.2334 4.4165 22.8223 3.7165 21.2C3.0165 19.5778 2.6665 17.8445 2.6665 16C2.6665 14.1556 3.0165 12.4223 3.7165 10.8C4.4165 9.17781 5.3665 7.7667 6.5665 6.5667C7.7665 5.3667 9.17762 4.4167 10.7998 3.7167C12.4221 3.0167 14.1554 2.6667 15.9998 2.6667C17.8443 2.6667 19.5776 3.0167 21.1998 3.7167C22.8221 4.4167 24.2332 5.3667 25.4332 6.5667C26.6332 7.7667 27.5832 9.17781 28.2832 10.8C28.9832 12.4223 29.3332 14.1556 29.3332 16Z"
              fill="#535B62"
            />
          </svg>
        </button>
      </div>
    </>
  );
}

export default SlideCard;
