import React, { useEffect, useState } from "react";
import KeyLayout from "../Components/KeyFeature/KeyLayout";
import { Icardprops } from "../Components/BenefitsCard/OurValueCard";
import keycard1 from "./assets/keysol5card1.jpg";
import keycard2 from "./assets/keysol5card2.jpg";
import keycard3 from "./assets/keysol5card3.jpg";
import keycard4 from "./assets/keysol5card4.jpg";
import keycard5 from "./assets/keysol5card5.jpg";
import BenefitLayout from "../Components/BenefitsCard/BenefitLayout";
import benefitcard1 from "./assets/benefitsol5card1.jpg";
import benefitcard2 from "./assets/benefitsol5card2.jpg";
import benefitcard3 from "./assets/benefitsol5card3.jpg";
import benefitcard4 from "./assets/benefitsol5card4.jpg";
import benefitcard5 from "./assets/benefitsol5card5.jpg";
import benefitcard6 from "./assets/benefitsol5card6.jpg";
import LogoBanner from "../../../Common/LogoBanner/LogoBanner";
import TextImgBanner from "../Components/TextImgBanner/TextImgBanner";
import solBanner5 from "./assets/solbanner5.svg";
import Banner from "../../../Common/Banner/Banner";
import bannerImg from "../Assets/solnBannerImg.svg";

const KeyFeatureData: Icardprops[] = [
  {
    heading: "In-App Discussion Board and Meetings",
    subheadings:
      "Our discussion boards help collaborate to identify and understand process risks. They help refine potential control measures and are a central hub for monitoring progress, documenting risk discussions, and sharing insights. This documented record simplifies audits and compliance and ensures transparency. Teams can brainstorm in meetings to mitigate risks.",
    image: keycard1,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "Actionable Dashboard and Notifications",
    subheadings:
      "They provide a real-time, centralized picture of your risk landscape and prompt necessary actions. Reminders inform control owners of risk, provide actionable insights, suggest potential solutions, and prompt them to take action. These two features effectively help proactively manage risk, make data-driven decisions, and prioritize risk mitigation efforts.",
    image: keycard2,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "Pre-Defined Templates",
    subheadings:
      "Templates for processes, risks, and controls streamline your risk management efforts, ease design, save time, and ensure consistency. Process templates help outline process workflows, assign owners, and identify metrics. Risk templates primarily provide common risk categories and risk mitigation suggestions. And control templates are libraries of best practices for mitigating specific risks.",
    image: keycard3,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "Map Process Repository to Other RegTech Applications",
    subheadings:
      "We offer a centralized repository for all your documented business processes, streamlining compliance. Our solution allows you to map your processes within the repository to specific regulations or reporting requirements stored in the RegTech applications, streamlining data flow, reducing the risk of gaps, and enhancing efficiency. ",
    image: keycard4,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "Control Testing and Risk Register",
    subheadings:
      "Record all identified process risks with our risk register, track residual risks, and implement controls to mitigate them. Control testing enables you to verify whether your implemented controls are actually working as intended. Also, KRIs should be monitored to track the effectiveness of the control in mitigating specific risks.",
    image: keycard5,
    cardcontainercss: { height: "352px" },
  },
];

const benefitcardData: Icardprops[] = [
  {
    heading: "Proactive Risk Mitigation",
    subheadings:
      "Features like risk registers, control testing, centralized dashboards, and collaborative discussion boards offer a holistic view of your risk landscape and reduce risk exposure.",
    image: benefitcard1,
    cardcontainercss: { height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)" },
  },
  {
    heading: "Improved Efficiency",
    subheadings:
      "Process family design and control mapping help reuse established controls and best practices across similar processes, saving time and effort.",
    image: benefitcard2,
    cardcontainercss: { height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)" },
  },
  {
    heading: "Enhanced Control Effectiveness",
    subheadings:
      "Choose the most effective controls and verify their functionality through regular control testing, ensuring they mitigate risks effectively.",
    image: benefitcard3,
    cardcontainercss: { height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)" },
  },
  {
    heading: "Streamlined Compliance",
    subheadings:
      "Identify which processes are subject to specific regulations and ensure controls address compliance requirements.",
    image: benefitcard4,
    cardcontainercss: { height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)" },
  },
  {
    heading: "Data-Driven Decision Making",
    subheadings:
      "Real-time process monitoring dashboards help get insights into process performance and control effectiveness.",
    image: benefitcard5,
    cardcontainercss: { height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)" },
  },
  {
    heading: "Improved Collaboration",
    subheadings:
      "With features like dashboards and discussion boards, facilitate knowledge sharing and ensure all stakeholders are informed and accountable.",
    image: benefitcard6,
    cardcontainercss: { height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)" },
  },
];
const imgTextBannerData = {
  mainContainerStyles: { width: "100%", height: "clamp(29.3125rem, 18.9189rem + 16.2242vw, 32.75rem)" },
  textContainerStyles: { width: "clamp(29.75rem, 7.8289rem + 34.2183vw, 37rem)", height: "clamp(25.5625rem, 18.9484rem + 10.3245vw, 27.75rem)" },
  heading:
    "Comprehensive, powerful process risks and controls management solution",
  img: solBanner5,
  imgcss:{
    width: "clamp(30.0625rem, -9.4333rem + 61.6519vw, 43.125rem)",
    height: "clamp(22.8125rem, -7.2345rem + 46.9027vw, 32.75rem)",
  }
};
const imgTextBannerData360 = {
  mainContainerStyles: {  width: "328px",
    marginLeft:"auto",
    marginRight:"auto", height: "739px" },
  textContainerStyles: { width: "328px", height: "418px" },
  heading:
    "Comprehensive, powerful process risks and controls management solution",
  img: solBanner5,
  imgcss:{
    width: "clamp(30.0625rem, -9.4333rem + 61.6519vw, 43.125rem)",
    height: "clamp(22.8125rem, -7.2345rem + 46.9027vw, 32.75rem)",
  }
};


const ImgTextBanner = () => {
  return (
    <div>
      Manage end-to-end process risk by identifying, assessing, and monitoring
      risks across your business operations.
      <br /> <br />
      Support and enhance your organization's Risk and Control Self-Assessment
      (RCSA) process by designing process families and using risk libraries to
      simplify risk identification. Process families map similar processes and
      assess risk at the family level, saving time and boosting efficiency.
      Ensure effective control management to mitigate identified risks with
      centralized control inventory and control designing and testing.
      Proactively mitigate risks by early identifying control weaknesses and
      emerging risks through KRI monitoring. Real-time monitoring dashboards
      keep you informed about process performance, allowing you to make
      data-driven adjustments. 
      <br /> <br />
      Customize a solution for your business today to optimize efficiency and
      meet regulatory requirements.
    </div>
  );
};

const bannerData = {
  title:
    "Streamline processes & ensure compliance with EvoNext’s risks and controls management solution",
  subtitle:
    "Identify, assess, and mitigate risks across your business operations with our robust internal controls management solution.\n\nLeverage the knowledge and experience of our risk management specialists to achieve comprehensive operational excellence. Trusted by enterprises of every size, our solution proactively manages process risk, optimizes controls coverage, and improves decision-making. Our collaborative and secure solution is highly customizable to meet your business goals. \n\nExplore how you can integrate with our comprehensive RegTech solution for a holistic view of risk and streamlined compliance. Reduce disruptions, demonstrate compliance, and achieve a more robust and secure operating environment today!",
  image1: bannerImg,
  imgcss: { right: "0" },
  buttontext: "Request Demo",
  button: true,
  buttoncss: "requestdemobtn",
  textcss: { width: "clamp(18.5rem, -32.9781rem + 107.1066vw, 58.0625rem)" },
  imgClass:"right"
};
const ProcessRisks = () => {
  const [textimagebannerdata, setCardsData] = useState( window.innerWidth<=800 ? imgTextBannerData360 : imgTextBannerData);
  useEffect(() => {
    const handleResize = () => {
      const newData =
      window.innerWidth<=800 ? imgTextBannerData360 : imgTextBannerData;
      setCardsData(newData);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section>
      <div className="aboutusbannerimg">
        <Banner data={bannerData} />
      </div>
      <div className="adjustment-container">
        <TextImgBanner
          mainContainerStyles={textimagebannerdata.mainContainerStyles}
          textContainerStyles={textimagebannerdata.textContainerStyles}
          heading={textimagebannerdata.heading}
          img={textimagebannerdata.img}
          imgcss={textimagebannerdata.imgcss}
        >
          <ImgTextBanner />
        </TextImgBanner>
      </div>
      <KeyLayout data={KeyFeatureData} />
      <BenefitLayout data={benefitcardData} />
      <div className="logo-bannerabout">
        <LogoBanner text={"Streamline processes and ensure compliance!"} />
      </div>
    </section>
  );
};

export default ProcessRisks;
