import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import Scrolltotop from "../ScrolltoTop/Scrolltotop";

// @ts-ignore
const Layout = () => {
  return (
    <>
      <Scrolltotop />
      <Header />
      <div className="header-space"></div>
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
