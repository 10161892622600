import Banner from "../../Common/Banner/Banner";
import LogoBanner from "../../Common/LogoBanner/LogoBanner";
import "./AboutUs.css";
import GrcExcellence from "./Components/GRCExcellence/GrcExcellence";
import OurValues from "./Components/OurValues/OurValues";
import Ourvision from "./Components/OurVision/Ourvision";
import bannerimg from "./assets/aboutusbannerimage.svg";
const bannerData = {
  title: "Who are we?",
  subtitle:
    "We make compliance simple. \nAt EvoNext, we are a team of passionate experts in risk management, regulatory compliance, and cutting-edge technology, aiming to revolutionize the global market. Our unified RegTech platform offers solutions for business continuity, outsourcing risks, process management, AI governance, and operational risk change management. Trust from our patrons and experienced partners has helped our customers achieve sustainable growth within the regulatory boundaries.",
  image1: bannerimg,
  imgClass: "aboutus-banner-img",
  button: true,
  // textcss: { width: "clamp(29.4375rem, 14.1305rem + 23.8938vw, 34.5rem)" },
  textcss: { width: "clamp(18.5rem, -2.1443rem + 42.953vw, 34.5rem)" },
 
};
const AboutUs = () => {
  return (
    <section className="aboutus-main-container">
      <div className="aboutusbannerimg">
        <Banner data={bannerData} />
      </div>
      <OurValues />
      <Ourvision />
      <GrcExcellence />
      <div className="logo-bannerabout">
        <LogoBanner
          text={
            "Simplify compliance, empower risk management, and achieve operational excellence with our comprehensive RegTech solutions"
          }
        />
      </div>
    </section>
  );
};
export default AboutUs;
