import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./HelpForm.css";
import DropDown from "../../../../../Common/DropDown/DropDown";
import DropDownIcon from "../../../../../Common/dropdownSvg/dropdown.svg";
import CountryList from "../../../../../Common/CountryList/CountryList";
import Loader from "../../../../../Common/Loader/loader";

const HelpForm = ({ onSubmitForm }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const emailValidation = Yup.string()
    .required("Please fill in all required fields")
    .test("is-valid-email", (value, context) => {
      if (!value)
        return context.createError({ message: "Email address is required" });
      const atSymbolCount = (value.match(/@/g) || []).length;
      if (atSymbolCount !== 1) {
        return context.createError({
          message: "Email must contain one '@' symbol",
        });
      }

      const atIndex = value.indexOf("@");
      if (atIndex < 1) {
        return context.createError({
          message:
            "The '@' symbol cannot be the first character and must have at least one character before it",
        });
      }

      const domainPart = value.substring(atIndex + 1);
      const domainParts = domainPart.split(".");
      if (domainParts.length < 2) {
        return context.createError({
          message: "The domain part must contain at least one period ('.')",
        });
      }

      const topLevelDomain = domainParts[domainParts.length - 1];
      if (topLevelDomain.length < 2) {
        return context.createError({
          message:
            "The domain part must end with at least two characters after the last period",
        });
      }

      const localPart = value.substring(0, atIndex);
      const validCharactersRegex = /^[A-Z0-9._%+-]+$/i;
      const validDomainRegex = /^[A-Z0-9._%+-]+$/i;

      if (!validCharactersRegex.test(localPart)) {
        return context.createError({
          message:
            "The invalid characters used. Only '.', '-', and '_' are allowed",
        });
      }
      if (localPart.includes(" ")) {
        return context.createError({
          message: "The email should not contain spaces",
        });
      }
      if (!validDomainRegex.test(domainPart)) {
        return context.createError({
          message:
            "The domain part contains invalid characters. Only '.', '-', and '_' are allowed",
        });
      }

      return true;
    });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[a-zA-Z]{3,}$/, "Invalid Data Entered")
      .required("Please fill in all required fields"),
    lastName: Yup.string().matches(/^[a-zA-Z ]*$/, {
      message: "Invalid Data Entered",
      excludeEmptyString: true,
    }),
    businessMail: emailValidation,
    companyStrength: Yup.string().required(
      "Please fill in all required fields"
    ),
    companyName: Yup.string()
      .trim()
      .required("Please fill in all required fields"),
    country: Yup.string().required("Please fill in all required fields"),
    mobileNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .required("Please fill in all required fields"),
    message: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      businessMail: "",
      country: "",
      message: "",
      companyName: "",
      mobileNo: "",
      companyStrength: "",
      callingCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitting(true);
      console.log(values);
      try {
        await axios.post("https://www.deprontoinfotech.com/api/formSubmit.php", {
          ...values,
          ["website"]: "evonext website",
        });
        resetForm();
        onSubmitForm();
      } catch (error) {
        console.error("Error submitting form", error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  // country dropdown data
  const [countryDropDownFlag, setCountryDropDownFlag] = useState(false);

  const countryClick = () => {
    setCountryDropDownFlag(!countryDropDownFlag);
  };

  const OptionCheck = (item) => {
    formik.setFieldValue("country", item.name);
    formik.setFieldValue("callingCode", "+" + item.callingCode);
    setCountryDropDownFlag(false);
  };

  // mobile no field data
  const companyStrengthOptions = [
    { name: "<=50" },
    { name: "51 - 250" },
    { name: "251 - 1000" },
    { name: "1001 - 5000" },
    { name: ">5000" },
  ];
  const [companyStrengthDropDownFlag, setCompanyStrengthDropDownFlag] =
    useState(false);

  const companyStrengthClick = () => {
    setCompanyStrengthDropDownFlag(!companyStrengthDropDownFlag);
  };

  const CompanyStrengthCheck = (item) => {
    formik.setFieldValue("companyStrength", item.name);
    setCompanyStrengthDropDownFlag(false);
  };

  // number keys
  const numberKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  return (
    <form onSubmit={formik.handleSubmit} className="contact-form">
      <div className="form-group">
        <label htmlFor="firstName" className="form-label">
          First Name<sup className="supMandatory">*</sup>
        </label>
        <input
          autoComplete="off"
          type="text"
          id="firstName"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.submitCount > 0 ? formik.handleBlur : null}
          className="form-input"
        />
        {formik.touched.firstName && formik.errors.firstName ? (
          <div className="error">{formik.errors.firstName}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="lastName" className="form-label">
          Last Name
        </label>
        <input
          autoComplete="off"
          type="text"
          id="lastName"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="form-input"
        />
        {formik.touched.lastName && formik.errors.lastName ? (
          <div className="error">{formik.errors.lastName}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="businessMail" className="form-label">
          Email Address<sup className="supMandatory">*</sup>
        </label>
        <input
          autoComplete="off"
          type="email"
          id="businessMail"
          name="businessMail"
          value={formik.values.businessMail}
          onChange={formik.handleChange}
          onBlur={formik.submitCount > 0 ? formik.handleBlur : null}
          className="form-input"
        />
        {formik.touched.businessMail && formik.errors.businessMail ? (
          <div className="error">{formik.errors.businessMail}</div>
        ) : null}
      </div>
      <div className="occupy-space"></div>
      <div className="form-group">
        <label htmlFor="companyName" className="form-label">
          Company Name<sup className="supMandatory">*</sup>
        </label>
        <input
          autoComplete="off"
          type="text"
          id="companyName"
          name="companyName"
          value={formik.values.companyName}
          onChange={formik.handleChange}
          onBlur={formik.submitCount > 0 ? formik.handleBlur : null}
          className="form-input"
        />
        {formik.touched.companyName && formik.errors.companyName ? (
          <div className="error">{formik.errors.companyName}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="companyStrength" className="form-label">
          Company Strength<sup className="supMandatory">*</sup>
        </label>
        <input
          autoComplete="off"
          readOnly
          type="text"
          id="companyStrength"
          name="companyStrength"
          value={formik.values.companyStrength}
          onClick={companyStrengthClick}
          className="form-input dropdownInput"
        />
        <div
          className="dropdownIcon"
          onClick={companyStrengthClick}
          style={{ cursor: "pointer" }}
        >
          <img src={DropDownIcon} alt="" />
        </div>
        {companyStrengthDropDownFlag && (
          <DropDown
            options={companyStrengthOptions}
            onOptionClick={CompanyStrengthCheck}
            onClose={() =>
              setCompanyStrengthDropDownFlag(!companyStrengthDropDownFlag)
            }
            isSearchEnable={false}
            containerClass={"companyStrengthDropdownContainer"}
            containerItemclass={"companyStrengthDropdownItem"}
          />
        )}
        {formik.touched.companyStrength && formik.errors.companyStrength ? (
          <div className="error">{formik.errors.companyStrength}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="country" className="form-label">
          Country<sup className="supMandatory">*</sup>
        </label>
        <input
          autoComplete="off"
          readOnly
          type="text"
          id="country"
          name="country"
          value={formik.values.country}
          onClick={countryClick}
          className="form-input dropdownInput"
        />
        <div
          className="dropdownIcon"
          onClick={countryClick}
          style={{ cursor: "pointer" }}
        >
          <img src={DropDownIcon} alt="" />
        </div>
        {countryDropDownFlag && (
          <DropDown
            options={CountryList}
            onOptionClick={OptionCheck}
            onClose={() => setCountryDropDownFlag(!countryDropDownFlag)}
            isSearchEnable={true}
          />
        )}
        {formik.touched.country && formik.errors.country ? (
          <div className="error">{formik.errors.country}</div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="mobileNo" className="form-label">
          Mobile Number<sup className="supMandatory">*</sup>
        </label>
        <input
          autoComplete="off"
          type="number"
          id="mobileNo"
          name="mobileNo"
          onKeyDown={(e) => {
            if (
              isNaN(Number(e.key)) &&
              !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(
                e.code
              )
            ) {
              e.preventDefault();
            }
            if (e.target.value.length === 10 && numberKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
          value={formik.values.mobileNo}
          onChange={formik.handleChange}
          onBlur={formik.submitCount > 0 ? formik.handleBlur : null}
          className="form-input"
          style={{ paddingLeft: "44px" }}
        />
        <span className="countryCode">{formik.values.callingCode} </span>
        {formik.touched.mobileNo && formik.errors.mobileNo ? (
          <div className="error">{formik.errors.mobileNo}</div>
        ) : null}
      </div>
      <div className="form-group message">
        <label htmlFor="message" className="form-label">
          Message
        </label>
        <textarea
          rows={3}
          cols={50}
          maxLength={200}
          type="text"
          id="message"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="form-input form-input-textarea"
        ></textarea>
        <div className="msgCharacterCount">
          {formik.values.message.length}/200{" "}
        </div>
        {formik.touched.message && formik.errors.message ? (
          <div className="error">{formik.errors.message}</div>
        ) : null}
      </div>
      <button type="submit" className="form-button" disabled={isSubmitting}>
        {isSubmitting ? <Loader /> : "Submit"}
      </button>
    </form>
  );
};

export default HelpForm;
