import LocMap from "./Components/LocMap/LocMap";
import "./contactus.styles.css";
import mapImg from "./assets/mapbanner.svg";
import mapimg360 from "./assets/mapimgworld360.svg";
import contactIcon from "./assets/contactIcon.svg";
import Banner from "../../Common/Banner/Banner";
import HelpForm from "../Home/Components/FindOutHelpForm/helpForm/HelpForm";
import { useEffect, useState } from "react";
import ThankYou from "./Components/ThankYouSec/ThankYou";
const bannerData = {
  title: "Contact Us",
  subtitle:
    "We are happy to help if you want to learn more or have any questions.",
  image1: mapImg,
  image2: contactIcon,
  img2css: { height: 76, width: 76 },
  layOutCss: { height: "clamp(10.75rem, 10.1755rem + 2.5602vw, 11.8125rem)" },
};
const bannerData360 = {
  title: "Contact Us",
  subtitle:
    "We are happy to help if you want to learn more or have any questions.",
  image1: mapimg360,
  image2: contactIcon,
  imgClass:"contactmap",
  textcss:{width:"296px"},
  img2css: { height: 36, width: 36 },
  layOutCss: { height: "clamp(10.75rem, 10.1755rem + 2.5602vw, 11.8125rem)" },
};
const ContactUs = () => {

  const [cardsData, setCardsData] = useState(  window.innerWidth<=800 ? bannerData360 : bannerData);
  useEffect(() => {
    const handleResize = () => {
      const newData =
      window.innerWidth<=800 ?bannerData360 : bannerData;
      setCardsData(newData);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [isFormSuccessFull, setIsFormSuccessfull] = useState(false);
  const onSubmitForm = () => {
    setIsFormSuccessfull(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className="contact-page-container">
      <Banner data={cardsData} />
      {isFormSuccessFull ? (
        <ThankYou setIsFormSuccessfull={setIsFormSuccessfull} />
      ) : (
        <section className="main-contact-container">
          <section className="location-contaner">
            <section className="container-title">Office Locations</section>
            <ul className="offieloc-names">
              <li>Mumbai</li>
              <li>London</li>
            </ul>
            <LocMap />
          </section>
          <section className="form-contaner">
            <section className="container-title">
              Get in touch and <br className="container-title-break" /> let us
              know how we can help
            </section>
            <HelpForm onSubmitForm={onSubmitForm} />
          </section>
        </section>
      )}
    </section>
  );
};

export default ContactUs;
