import OurValueCard, {
  Icardprops,
} from "../../../SolutionPages/Components/BenefitsCard/OurValueCard";
import "./OurValues.css";
import img1 from "./assets/valuecard1.jpg";
import img2 from "./assets/valuecard2.jpg";
import img3 from "./assets/valuecard3.jpg";
import img4 from "./assets/valuecard4.jpg";
const ourValueData: Icardprops[] = [
  {
    heading: "Continuous Improvement",
    subheadings:
      "We believe small wins add up to make big impacts. We are dedicated to ongoing innovation and quality improvement.",
    image: img1,
  },
  {
    heading: "Customer-Centric & Cost-Effective",
    subheadings:
      "We put customers first by offering secure, scalable, cost-effective solutions that are customizable for diverse use cases.",
    image: img2,
  },
  {
    heading: "Simplified Compliance",
    subheadings:
      "EvoNext strives to make efficiency and compliance effortless with automation, integrations, and centralization.",
    image: img3,
  },
  {
    heading: "Data-Driven Decision-Making",
    subheadings:
      "We provide insights to improve decision-making and proactive risk management.",
    image: img4,
  },
];
const OurValues = () => {
  return (
    <section className="ourvalues-main-container">
      <div className="heading">Our Values</div>
      <div className="valuecards">
        {ourValueData.map((card) => (
          <OurValueCard data={card} />
        ))}
      </div>
    </section>
  );
};
export default OurValues;
