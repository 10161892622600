import React, { useEffect, useState } from "react";
import "./OperationalRisk.css";
import LogoBanner from "../../../Common/LogoBanner/LogoBanner";
import BenefitLayout from "../Components/BenefitsCard/BenefitLayout";
import { Icardprops } from "../Components/BenefitsCard/OurValueCard";
import img1 from "./assets/benefitsolcard1.jpg";
import img2 from "./assets/benefitsolcard2.jpg";
import img3 from "./assets/benefitsolcard3.jpg";
import img4 from "./assets/benefitsolcard4.jpg";
import img5 from "./assets/benefitsolcard5.jpg";
import img6 from "./assets/benefitsolcard6.jpg";

import key1 from "./assets/keysol1.jpg";
import key2 from "./assets/keysol2.jpg";
import key3 from "./assets/keysol3.jpg";
import key4 from "./assets/keysol4.jpg";
import key5 from "./assets/keysol5.jpg";
import KeyLayout from "../Components/KeyFeature/KeyLayout";
import Banner from "../../../Common/Banner/Banner";
import solBanner1 from "../Assets/solbanner1.svg";
import TextImgBanner from "../Components/TextImgBanner/TextImgBanner";
import bannerImg from "../Assets/solnBannerImg.svg";

const benefitcardData: Icardprops[] = [
  {
    heading: "Reduced Disruptions",
    subheadings:
      "Our structured and collaborative approach to managing change helps proactively identify and address risks, leading to smoother change implementation.",
    image: img1,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)",
    },
  },
  {
    heading: "Enhanced Risk Management",
    subheadings:
      "Features like well-defined workflows, audit trails, real-time data, and integrations help with proactive identification and risk mitigation.",
    image: img2,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)",
    },
  },
  {
    heading: "Improved Communication",
    subheadings:
      "Dashboards and discussion boards boost transparency, business goal alignment, and communication.",
    image: img3,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)",
    },
  },
  {
    heading: "Streamlined Compliance",
    subheadings:
      "Centralized documentation and defined workflow ensure adherence to regulations.",
    image: img4,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)",
    },
  },
  {
    heading: "Increased Efficiency",
    subheadings:
      "Streamlined workflows help with faster task completion times and reduced rework.",
    image: img5,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)",
    },
  },
  {
    heading: "Greater Agility",
    subheadings:
      "By proactively managing risks and assessing controls, adapt to changing markets and internal needs.",
    image: img6,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.3359rem + 3.7225vw, 8.5rem)",
    },
  },
];

const KeyFeatureData: Icardprops[] = [
  {
    heading: "Collaborative Workflow",
    subheadings:
      "Our solution offers streamlined task management and collaboration with in-app discussion boards and meeting schedulers. It facilitates improved decision-making with the team’s collective knowledge and real-time feedback.",
    image: key1,
    cardcontainercss: {
      height: "301px",
    },
  },
  {
    heading: "Actionable Dashboard",
    subheadings:
      "Our centralized dashboards display the progress of change initiatives from initiation to sign-offs.  They offer a shared view enabling real-time monitoring and identification of potential roadblocks, empowering stakeholders to take necessary actions.",
    image: key2,
    cardcontainercss: {
      height: "301px",
    },
  },
  {
    heading: "Pre-Built Templates",
    subheadings:
      "Our standardized and well-designed templates provide a pre-defined framework for change management, saving time and effort. They empower teams to manage risk associated with change more efficiently and consistently.",
    image: key3,
    cardcontainercss: {
      height: "301px",
    },
  },
  {
    heading: "Automated Checks and Controls",
    subheadings:
      "Automating follow-up and assigning owners and due dates help ensure that conditional sign-off tasks are completed and don't get overlooked. We also enable uploading documents or providing evidence, creating a clear audit trail, and demonstrating compliance. ",
    image: key4,
    cardcontainercss: {
      height: "301px",
    },
  },
  {
    heading: "Ready-To-Digest Reports",
    subheadings:
      "Our reports present complex data on change progress, risk assessments, and mitigation efforts in a user-friendly format for sharing with auditors, regulators, and all stakeholders, thus enabling data-driven decision-making.",
    image: key5,
    cardcontainercss: {
      height: "301px",
    },
  },
];
const bannerData = {
  title:
    "Minimize operational risk with EvoNext’s integrated change management solution",
  subtitle:
    "As a leading provider of regulatory compliance solutions, EvoNext is helping businesses worldwide navigate the complexities of operational risk. Our Operational Risk Change Management Solution offers a cost-effective and customizable solution for your organization, helping you make data-driven decisions and ensure effective risk mitigation.  \n\nOur team of specialists offer unparalleled domain and functional expertise. EvoNext's solution goes beyond Process and Product Change Management with integrated services encompassing Risk & Control Self-Assessment. This holistic approach empowers your organization to manage change effectively and boost operational resilience.",
  image1: bannerImg,
  imgcss: { right: "0" },
  buttontext: "Request Demo",
  button: true,
  buttoncss:"requestdemobtn",
  textcss: { width: "clamp(18.5rem, -10.531rem + 60.4027vw, 41rem)" },
  imgClass:"right"
};

const imgTextBannerData = {
  mainContainerStyles: {
    width: "100%",
    height: "clamp(24.0625rem, -12.0317rem + 56.3422vw, 36rem)",
    paddingTop: "clamp(0rem, -7.37rem + 11.5044vw, 2.4375rem)",
  },
  textContainerStyles: {
    width: "clamp(29.75rem, 7.8289rem + 34.2183vw, 37rem)",
    height: "clamp(20.3125rem, 11.4307rem + 13.8643vw, 23.25rem)",
  },
  heading:
    "Comprehensive & collaborative operational risk change management solution",
  img: solBanner1,
  imgcss: {
    width: "clamp(29.875rem, -17.5577rem + 74.0413vw, 45.5625rem)",
    height: "clamp(23.625rem, -13.792rem + 58.4071vw, 36r,em)",
  },
};
const imgTextBannerData360 = {
  mainContainerStyles: {
    width: "328px",
    marginLeft:"auto",
    marginRight:"auto",
    height: "647px",
    paddingTop: "clamp(0rem, -7.37rem + 11.5044vw, 2.4375rem)",
  },
  textContainerStyles: {
    width: "328px",
    height: "326px",
  },
  heading:
    "Comprehensive & collaborative operational risk change management solution",
  img: solBanner1,
  imgcss: {
    width: "clamp(29.875rem, -17.5577rem + 74.0413vw, 45.5625rem)",
    height: "clamp(23.625rem, -13.792rem + 58.4071vw, 36r,em)",
  },
};

const ImgTextBanner = () => {
  return (
    <div>
      Operational Risk Change Management isn't just about surviving change; it's
      about thriving on it.  <br /> <br />
      EvoNext’s solution automates and streamlines processes, making changes in
      policies, processes, products, and regulations seamless and efficient.
      Clear workflows and templates ensure compliance and mitigate risks
      associated with the transition.  <br /> <br />
      Effective communication channels, centralized data, and dashboard feature
      foster collaboration and transparency, leading to a more agile
      organization. Additionally, our solution's data-driven insights fuel
      continuous improvement, helping you improve your business's bottom line. 
    </div>
  );
};

const OperationalRisk = () => {
  const [textimagebannerdata, setCardsData] = useState( window.innerWidth<=800 ? imgTextBannerData360 : imgTextBannerData);
  useEffect(() => {
    const handleResize = () => {
      const newData =
      window.innerWidth<=800 ? imgTextBannerData360 : imgTextBannerData;
      setCardsData(newData);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section>
      <div className="aboutusbannerimg">
        <Banner data={bannerData} />
      </div>
      <div className="adjustment-container">
        <TextImgBanner
          mainContainerStyles={textimagebannerdata.mainContainerStyles}
          textContainerStyles={textimagebannerdata.textContainerStyles}
          heading={textimagebannerdata.heading}
          img={textimagebannerdata.img}
          imgcss={textimagebannerdata.imgcss}
        >
          <ImgTextBanner />
        </TextImgBanner>
      </div>
      <KeyLayout data={KeyFeatureData} />
      <BenefitLayout data={benefitcardData} />
      <div className="logo-bannerabout">
        <LogoBanner
          text={
            "Boost\ncontrols coverage and risk monitoring with our automated operational risk change management solution."
          }
        />
      </div>
    </section>
  );
};
export default OperationalRisk;
