import React, {
  createContext,
  useContext,
  useCallback,
  useRef,
  ReactNode,
} from "react";
import { useNavigate } from "react-router-dom";

interface ScrollContextType {
  scrollToForm: () => void;
  homeFormRef: React.RefObject<HTMLDivElement>;
}

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

interface ScrollProviderProps {
  children: ReactNode;
}

export const ScrollProvider: React.FC<ScrollProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const homeFormRef = useRef<HTMLDivElement>(null);

  const scrollToForm = useCallback(() => {
    setTimeout(() => {
      navigate("/home");
    }, 1);
    setTimeout(() => {
      homeFormRef.current?.scrollIntoView();
    }, 100);
  }, [navigate]);

  return (
    <ScrollContext.Provider value={{ scrollToForm, homeFormRef }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScrollContext = (): ScrollContextType => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useScrollContext must be used within a ScrollProvider");
  }
  return context;
};
