import "./HomeBanner.css";
// import homebannerimg from "../assets/HomeBanner.svg"; 
// import homebannerimg from "../assets/HomeBannerenhanced.svg";
// import homebannerimg from "../assets/1000066631 (1) 1.jpg";
import homebannerimg from "../assets/homepagebannerv2.png";
import { useNavigate } from "react-router";

const data = {
  // title:"Resilience isn't luck. Its a Strategy",
  subtitle:
    "Implement a unified risk management strategy with EvoNext’s integrated Governance, Risk & Compliance Platform (GRC) for lasting resilience.",
};
const HomeBanner = () => {
  const navigate = useNavigate();

  return (
    <div className="HomePageBannerContainer">
      <div className="homebanner-text-container">
        <div className="title">
          <span style={{ color: "#0E1091" }}>Resilience </span>
          <span style={{ color: "#2F363F" }}>isn't </span>
          <span style={{ color: "#0E1091" }}>luck</span>

          <span style={{ color: "#2F363F" }}>
            . <br className="line-break" />
            Its a{" "}
          </span>
          <span style={{ color: "#0E1091" }}>Strategy</span>
        </div>
        <div className="subtitle">{data.subtitle}</div>
        <div>
          <button
            className="getintouchbtn"
            onClick={() => {
              navigate("/contact");
            }}
          >
            Get in Touch
          </button>
        </div>
      </div>
      
      <img src={homebannerimg} alt="" className="Bannerimg"></img>
      
    </div>
  );
};
export default HomeBanner;
