import React from "react";
import "./OurVision.css";
import ourvision from "./assets/ourvision.svg";

function Ourvision() {
  return (
    <div className="vision-container">
      <div className="visionheading">Our Vision</div>
      <div className="ourvision-main-container">
        <div className="ourvision-content">
          Our vision is to be the trusted partner empowering organizations
          across industries to thrive in a dynamic risk landscape.
        </div>
        <img src={ourvision} className="ourvision-banner" />{" "}
      </div>
    </div>
  );
}

export default Ourvision;
