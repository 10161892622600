import React, { useEffect, useState } from "react";
import CareerOpprtunities from "./Components/CareerOpprtunities/CareerOpprtunities";
import CareerJourney from "./Components/CareerJourney/CareerJourney";
import "./careers.css";
import Banner from "../../Common/Banner/Banner";
import bannerimg from "./assets/carrerimg.svg";
import icon1 from "./assets/icon1.svg";
import icon2 from "./assets/icon2.svg";
import icon3 from "./assets/icon3.svg";
import icon4 from "./assets/icon4.svg";
import bannerimg360 from "./assets/carrerimg360.svg";

import WhatMakesUSdiffCard from "./Components/whatmakesuscards/WhatMakesUSdiffCard";

const bannerData = {
  title: "Careers",
  subtitle:
    "We are a group of dreamers building a world class product. Join us and let us make RegTech fun!",
  image1: bannerimg,
  imgcss: { right: "0" },
  button: true,
  titlecss: { marginBottom: "8px" },
  textcss: { width: "clamp(18.5rem, -9.4021rem + 58.0537vw, 40.125rem)" },
  imgClass: "careers-banner-img",
};
const bannerData360 = {
  title: "Careers",
  subtitle:
    "We are a group of dreamers building a world class product. Join us and let us make RegTech fun!",
  image1: bannerimg,
  imgcss: { right: "0" },
  button: true,
  titlecss: { marginBottom: "4px" },
  textcss: { width: "clamp(18.5rem, -9.4021rem + 58.0537vw, 40.125rem)" },
  imgClass: "careers-banner-img",
};
const whamakesusdiffcarddata = [
  {
    img: icon1,
    title: "Diverse Roles",
    description:
      "Our team members work on a range of features, offering a diverse selection of roles, ensuring there’s always something to match your skills and interests. You’ll have the opportunity to develop your skills by working on various enterprise level complexity, alongside other talented IT professionals.",
  },
  {
    img: icon2,
    title: "Impressive Reputation",
    description:
      "We’ve been around for more than a decade, building an incredible reputation within our industry. And we intend to keep this reputation, meaning we always keep the skills of our team members up to date, so they can seamlessly transfer into new and exciting roles. You won’t grow stagnant here – you’ll continuously evolve as a domain and technical expert.",
  },
  {
    img: icon3,
    title: "Major Partners",
    description:
      "We work with some of the biggest names in their respective industries, including Tier 1 Banks, Financial Services, Insurance companies, allowing you to get experience working on significant projects with big impacts. Working on complex problems will enhance the CV of any IT professional.",
  },
  {
    img: icon4,
    title: "Industry Leading Benefits",
    description:
      "We know that getting the best experts means offering the best benefits. We offer industry-leading benefits to attract the best talent, including health insurance, remote working, wellness benefits, birthday & bereavement leave, etc. We believe the best workers should be treated to the best benefits – it’s that simple. ",
  },
];
const Careers=()=> {
  const [textimagebannerdata, setCardsData] = useState( window.innerWidth<=800 ? bannerData360 : bannerData);
  useEffect(() => {
    const handleResize = () => {
      const newData =
      window.innerWidth<=800 ? bannerData360 : bannerData;
      setCardsData(newData);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="career-page-main-container">
      <Banner data={textimagebannerdata} />
      <section className="whatmakesusdiffHeading">
        What Makes Us Different
      </section>

      <section className="whatmakesusdiffContainer">
        {whamakesusdiffcarddata.map((item, i) => (
          <span key={i}>
            {" "}
            <WhatMakesUSdiffCard data={item} />{" "}
          </span>
        ))}
      </section>
      <CareerOpprtunities />
      <CareerJourney />
    </div>
  );
}

export default Careers;
