import "./GrcExcellence.css";
const GrcExcellence = () => {
  return (
    <section className="grc-mainContainer">
      <div className="heading-grc">GRC Excellence By Numbers</div>
      <div className="subcontent-grc">
        We have been putting this vision into action for more than a decade,
        working with risk professionals and serving world-class companies of
        every shape and size. Over the years, we have helped leading Banking and
        Financial Services sector brands build trust with stakeholders by
        demonstrating a commitment to ethical practices, comprehensive risk
        management, and a collaborative operating environment.
      </div>
      <div className="subheading-grc">
        Large enterprises, including top Banks & Financial Services, rely on
        EvoNext.
      </div>
      <div className="grcbelow">
        <div className="section1">
          <div className="numbersection">100K+</div>{" "}
          <div className="section1text"> Users protected by our<br className="line-break"/> solution</div>
        </div>
        <div className="section2">
          <div className="numbersection">10+</div>Years of experience in
          building resilience
        </div>
      </div>
    </section>
  );
};
export default GrcExcellence;
