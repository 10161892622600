import "./BenefitLayout.css"
import OurValueCard, { Icardprops } from "./OurValueCard";
interface BenefitLayoutProps {
    data: Icardprops[];
  }
const BenefitLayout=({data}:BenefitLayoutProps)=>{
   
    return(
        <section className="benefits-main-container">
             <div className="headingbenefit">
             Benefits
             </div>
             <div className="benefitcardcontainer">
        {data.map((card) => (
              <OurValueCard
                 data={card}
              />
          ))}
        </div>
        </section>
    );
}
export default BenefitLayout;