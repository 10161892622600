import WhyChooseCard from "../../../Common/WhyChooseCard/WhyChooseCard";
import "./WhyChooseUs.css";
import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.jpg";
import img3 from "./assets/img3.jpg";
import img4 from "./assets/img4.jpg";
import img5 from "./assets/img5.jpg";
import img6 from "./assets/img6.jpg";
const data = {
  title: "Why Choose Us?",
  subtitle:
    "Eliminate the need for multiple-point solutions. EvoNext's unified platform offers centralized solutions for risk and compliance challenges. Our user-friendly interface enables informed decision-making across stakeholders and functions, while robust features enable automation and reduce effort.",
};
const cardData = [
  {
    heading: "Robust security to protect against vulnerabilities",
    subheadings: [
      "On-premise deployment for greater security and privacy",
      "Role-based access control to manage user access",
      "Data encryption for data privacy and security",
      "Vulnerabilities management as per OWASP standards",
      "Monitoring and logging to track user-level activity",
      "Maker/Checker feature to add a reviewer and improve accuracy",
    ],

    image: img1,
  },
  {
    heading:
      "Enterprise-grade feature list for companies of every size and stage",
    subheadings: [
      "Discussion boards and dashboards for increased communication and monitoring",
      "Control Testing sub-workflow",
      "Integrated with Knowledge Management System",
      "Conditional sign-offs to enable a more controlled and efficient approval process",
      "Review process to improve collaboration and comprehensive risk identification",
    ],
    image: img2,
  },
  {
    heading: "Customizations to configure your way",
    subheadings: [
      "Setup workflows with modular design and conditional logic",
      "Customizable approval process",
      "Pre-built, customizable templates for risk, controls, processes, and more",
      "Customizable reporting and analytics",
      "Tailored APIs to exchange data with other internal systems",
      "Configurable notifications to receive alerts and reminders based on specific criteria",
    ],
    image: img3,
  },
  {
    heading:
      "Integrate with industry-leading systems for a unified environment",
    subheadings: [
      "HR Systems integration to establish a single source of truth for all employee data",
      "Identity & Access Management Systems integration for centralized user management ",
      "Knowledge Management Systems integration for knowledge centralization ",
      "Third-party Risk & Compliance Systems integration for improved risk management",
    ],
    image: img4,
  },
  {
    heading: "Audit logs for regulatory monitoring",
    subheadings: [
      "Enhanced change recording of who did what, when, and how",
      "Comprehensive user activity tracking for compliance, like accessing sensitive data",
      "Continuous monitoring to assess risk and improve efficiency",
      "Filter to narrow down log entries based on specific criteria (e.g., user, date range, action type)",
      "Export data in various formats for reporting or further analysis",
      "Log errors and exceptions, including stack traces and context information",
    ],
    image: img5,
  },
  {
    heading: "Centralized management to break silos and build transparency",
    subheadings: [
      "Comprehensive, secure, and single source of truth for all data",
      "Streamline planning and collaboration among collaborators",
      "Promote transparency and facilitate compliance",
      "Role-based access control for centralized user management",
      "Streamline processes with unified workflows across all GRC functions",
      "Features like dashboards, in-app meetings, and discussion board",
    ],
    image: img6,
  },
];
const WhyChooseUs = () => {
  return (
    <div className="whychooseus-container">
      <div className="text-container">
        <div className="titlewhy">{data.title}</div>
        <div className="whysubtitle">{data.subtitle}</div>
      </div>
      <div className="whychoosecards">
        {cardData.map((card, index) => (
          <WhyChooseCard
            key={index}
            heading={card.heading}
            subheadings={card.subheadings}
            image={card.image}
          />
        ))}
      </div>
    </div>
  );
};
export default WhyChooseUs;
