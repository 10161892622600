import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Layout from "./Common/Layout/Layout";
import Home from "./Pages/Home/Home";
import ContactUs from "./Pages/ContactUs/ContactUs";
import { useEffect, useRef } from "react";
import "@fontsource/metropolis/100.css";
import "@fontsource/metropolis/200.css";
import "@fontsource/metropolis/300.css";
import "@fontsource/metropolis/400.css";
import "@fontsource/metropolis/500.css";
import OperationalRisk from "./Pages/SolutionPages/OperationalRisk/OperationalRisk";
import BusinessContinuity from "./Pages/SolutionPages/BusinessContinuity/BusinessContinuity";
import RiskManagement from "./Pages/SolutionPages/RiskManagement/RiskManagement";
import AiGovernance from "./Pages/SolutionPages/AiGovernance/AiGovernance";
import ProcessRisks from "./Pages/SolutionPages/ProcessRisks/ProcessRisks";
import AboutUs from "./Pages/AboutUs/AboutUs";
import { ScrollProvider } from "./ScrollContext";
import axios from "axios";
import Careers from "./Pages/Careers/careers";

function App() {
  return (
    <Router basename="/">
      <ScrollProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="home" replace />} />
            <Route index path="home" element={<Home />} />
            <Route index path="about" element={<AboutUs />} />
            <Route index path="contact" element={<ContactUs />} />
            <Route index path="careers" element={<Careers />} />
            <Route
              index
              path="solutions"
              element={<Navigate to="/solutions/operationalrisk" replace />}
            />
            <Route
              index
              path="solutions/operationalrisk"
              element={<OperationalRisk />}
            />
            <Route
              index
              path="solutions/businesscontinuity"
              element={<BusinessContinuity />}
            />
            <Route
              index
              path="solutions/riskmanagement"
              element={<RiskManagement />}
            />
            <Route
              index
              path="solutions/aigovernance"
              element={<AiGovernance />}
            />
            <Route
              index
              path="solutions/processrisks"
              element={<ProcessRisks />}
            />
          </Route>
        </Routes>
      </ScrollProvider>
    </Router>
  );
}

export default App;
