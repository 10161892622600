import "./SlideShow.css";
import SlideShowCard, { Idata } from "./SlideShowCard";
import slide1 from "../assets/slide1.jpg";
import slide2 from "../assets/slide2.jpg";
import slide3 from "../assets/slide3.jpg";
import slide4 from "../assets/slide4.jpg";
import slide5 from "../assets/slide5.jpg";

const slideshowData: Idata[] = [
  {
    heading: "Deliver Trustworthy AI and Ensure a Responsible AI Lifecycle",
    content:
      "Our AI Governance and Risk Management Solution ensures responsible development and use of AI. We help design AI policy, cross-collaboration for its ethical adoption, and monitor across upstream and downstream systems",
    image: slide1,
  },
  {
    heading: "Streamline Change Initiatives",
    content:
      "Our Process and Change Management Solution ensures seamless, more efficient transformation with end-to-end, customizable workflows, risk assessment, review with audit trail, and more",
    image: slide2,
  },
  {
    heading: "Prepare For Disruption",
    content:
      "With EvoNext’s Business Continuity Management Solution, conduct Business Impact Analysis (BIA), create Functional Recovery Plans (FRPs), and regularly test Business Continuity Plans (BCPs) to recover smoothly from disruptions and minimize downtime",
    image: slide3,
  },
  {
    heading: "Simplify Third-Party Management and Mitigate Risk",
    content:
      "Leverage our Outsourcing Risk Management Solution for efficient vendor management, strengthening compliance, and proactive risk management",
    image: slide4,
  },
  {
    heading: "Manage Risk and Ensure Compliance",
    content:
      "With our Internal Controls Management Solution, you can design process families and map risks and controls to identify and address control weaknesses proactively. It thus improves operational efficiency, demonstrates compliance, and ensures controls coverage",
    image: slide5,
  },
];

const SlideShow = () => {
  return (
    <div className="marquee">
      <div className="marquee-content">
        {slideshowData.map((data) => (
          <SlideShowCard data={data} />
        ))}
        {slideshowData.map((data) => (
          <SlideShowCard data={data} />
        ))}
      </div>
    </div>
  );
};

export default SlideShow;
