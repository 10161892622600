import { Icardprops } from "../BenefitsCard/OurValueCard";
import "./KeyFeatureCard.css";

const KeyFeatureCard = ({data}:{data:Icardprops}) => {
  const { heading, subheadings, image ,cardcontainercss} = data;

  return (
    <div style={cardcontainercss} className="keycardContainer">
      <div >
        <img className="keycardimage" src={image} alt=""></img>
      </div>
      <div className="keycardheading">{heading}</div>
      <div className="keysubheading">
        {subheadings}
      </div>
    </div>
  );
};
export default KeyFeatureCard;
