import { useNavigate } from "react-router-dom";
import "./thankyou.css";
import successImg from "../../assets/success.svg";
import backIcon from "../../assets/back.svg";

const ThankYou = ({
  setIsFormSuccessfull,
}: {
  setIsFormSuccessfull: (act: boolean) => void;
}) => {
  const navigate = useNavigate();
  return (
    <section className="formSuccessContainer">
      <div className="BackButton" onClick={() => setIsFormSuccessfull(false)}>
        <img src={backIcon} alt="back" />
      </div>
      <div className="SuccessTextContainer">
        <img src={successImg} alt="" className="sucessImg" />
        <span className="thanksText">Thank You</span>
        <div className="SucessText">Your form is submitted successfully</div>
        <div className="SucessText">
          We will be in touch and contact you soon!
        </div>
        <button
          className="navigateToHome"
          onClick={() => {
            navigate("/home");
          }}
        >
          Go to Home Page
        </button>
      </div>
    </section>
  );
};

export default ThankYou;
