import React, { ReactNode, Dispatch, useState, SetStateAction } from "react";
import "./copytextcomponent.css";

const CopyTextComponent = ({
  children,
  texttocopy,
  setIsExpanded,
}: {
  children: ReactNode;
  texttocopy: string;
  setIsExpanded?: Dispatch<SetStateAction<boolean>>;
}) => {
  const copytext = () => {
    console.log("clk");
    navigator.clipboard.writeText(texttocopy);
  };

  const [enableCopySecton, setEnableCopySection] = useState(false);

  return (
    <div
      className="copytxt-container"
      onMouseEnter={() => {
        setEnableCopySection(true);
        setIsExpanded?.(true);
      }}
      onMouseLeave={() => {
        setEnableCopySection(false);
        setIsExpanded?.(false);
      }}
    >
      {children}
      {enableCopySecton && (
        <button className="copybutton" onClick={copytext}>
          <span className="copytxt">Copy</span>
        </button>
      )}
    </div>
  );
};

export default CopyTextComponent;
