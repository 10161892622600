import callIcon from "../../assets/call.svg";
import mailIcon from "../../assets/mail.svg";
import locationIcon from "../../assets/location.svg";
import "./locatiomap.styles.css";
import { CSSProperties } from "react";

export interface Idata {
  address: string[];
  no: string;
  mail: string;
  containerStyle?: CSSProperties;
}

const LocCard = ({ data }: { data: Idata }) => {
  const { address, no, mail, containerStyle } = data;
  return (
    <>
      <div className="invisiblegap"></div>
      <div className="loccard-container" style={containerStyle}>
        <div className="loccard-text-row">
          <div>
            <img className="loccard-text-row-icon" src={locationIcon} alt="" />
          </div>
          <div className="loccard-text-row-text">
            <div className="addresscontainer">
              {address.map((address, i) => (
                <span key={i}>{address}</span>
              ))}
            </div>
          </div>
        </div>
        <div className="loccard-text-row">
          <div>
            <img className="loccard-text-row-icon" src={callIcon} alt="" />
          </div>
          <div className="loccard-text-row-text">{no} </div>
        </div>
        <div className="loccard-text-row">
          <div>
            <img className="loccard-text-row-icon" src={mailIcon} alt="" />
          </div>
          <div className="loccard-text-row-text">{mail} </div>
        </div>
      </div>
      <div className="invisiblegap"></div>
    </>
  );
};

export default LocCard;
