import { useEffect, useState } from "react";
import OurSolutionCard from "../../../../Common/OurSolutionCard/OurSolutionCard";
import "./OurSolutions.css";
import img1 from "./assets/our1.jpg";
import img2 from "./assets/our2.jpg";
import img3 from "./assets/our3.jpg";
import img4 from "./assets/our4.jpg";
import img5 from "./assets/our5.jpg";

import img10241 from "./assets/our1024card1.jpg";
import img10242 from "./assets/our1024card2.jpg";
import img10243 from "./assets/our1024card3.jpg";
import img10244 from "./assets/our1024card4.jpg";
import img10245 from "./assets/our1024card5.jpg";

const data = {
  title: "Solutions",
  subtitle:
    "EvoNext empowers businesses with integrated solutions to streamline risk management and build resilience. From operations to vendors, our platform simplifies processes, fosters informed decisions, helps in approval management, enables tracking and auditing issues, and ensures ongoing regulatory compliance.",
};

const cardData = [
  {
    headingContent: {
      title: "Operational Risk Change Management",
      subtitle:
        "Identify, mitigate, and monitor risks associated with planned policies, processes, products, or regulatory changes",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "Collaborative workflow with in-app discussion boards and meetings",
      "Actionable dashboard with real-time visibility",
      "Pre-built templates help streamline risk assessment",
      "Automated checks and controls and conditional sign-offs",
      "Ready-to-digest reports for auditors and regulators",
    ],

    image: img1,
    imgstyle: { marginRight: "32px", marginBottom: "32px" },
    knowmorebtn: "solutions/operationalrisk",
    textSection:{},
    cardContainerStyle:{},
    knowbtn:false
  },
  {
    headingContent: {
      title: "Business Continuity Management",
      subtitle:
        "Initiate business impact analysis, develop robust recovery plans, and conduct business continuity tests to respond effectively to disruptions and recover swiftly",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "In-App Issue Tracker helps manage multiple issues and track progress",
      "Actionable Dashboard and Notifications offer clear insights into overall BCP effectiveness and manage risk",
      "Drill Reports share insights and identify areas for improvement",
      "Planned and Unplanned Continuity Planning helps prepare for any eventuality",
      "Audit Trail enables maintaining records of BCM activities for compliance",
    ],

    image: img2,
    imgstyle: { marginRight: "0px", marginBottom: "0px" },
    knowmorebtn: "solutions/businesscontinuity",
    textSection:{},
    cardContainerStyle:{},
    knowbtn:false
  },
  {
    headingContent: {
      title: "Outsourcing Risk Management",
      subtitle:
        "Streamline onboarding, simplify auditing, enhance risk assessment, centralize management, and reduce risk to strengthen relationships with vendors, regulatory compliance, and risk management throughout the entire vendor lifecycle",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "Automated workflow improves the onboarding process and automates data collection with pre-built templates, document uploads, and background checks",
      "Risk Assessment and Monitoring periodically tracks vendor activities and regulatory changes to identify emerging risks and maintain updated profiles",
      "Actionable Dashboard offers real-time data for informed decision-making and a shared view of risk",
      "In-app Discussion Boards and Meetings offer a secure collaborative environment",
      "Follow-up Checks and Controls send automated reminders for completing tasks",
    ],

    image: img3,
    imgstyle: { marginRight: "-10px", marginBottom: "31px" },
    knowmorebtn: "solutions/riskmanagement",
    textSection:{},
    cardContainerStyle:{},
    knowbtn:false
  },
  {
    headingContent: {
      title: "AI Governance and Risk Management",
      subtitle:
        "Design AI policy and guardrails for responsible AI development and use. Map and monitor the risks of AI usage across applications. Monitor AI models and use Explainability Trail across teams to improve transparency and trust",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "Collaborative Environment with In-App Discussion Board & Meetings help standardize governance and break down silos",
      "Actionable Dashboard and Notifications provide real-time insights, customizable views, and take timely actions",
      "Follow-up Checks and Controls promote continuous monitoring and early detection of issues",
      "Link Business Outcomes with Explainable Algorithms to help understand the model’s impact, ensure regulatory compliance, and build trust",
    ],

    image: img4,
    imgstyle: { marginRight: "0px", marginBottom: "0px" },
    knowmorebtn: "solutions/aigovernance",
    textSection:{},
    cardContainerStyle:{},
    knowbtn:false
  },
  {
    headingContent: {
      title: "Process Risks & Controls Management",
      subtitle:
        "Catalog new processes in the library by obtaining approval and mapping them with risks and controls. Thus, you can have reusable processes and build a process family",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "A Collaborative Environment with an In-App Discussion Board & Meetings module helps centralize knowledge, identify risks, and streamline control testing",
      "Actionable Dashboard and Notifications offer real-time control test score and reminders to control owners",
      "Templates for Processes, Risks, and Controls ensure ease in design and documentation",
      "Control Testing and Risk Register help proactively identify, assess, and mitigate process risks",
      "Map process repository to other GRC applications and streamline data flow to help in risk identification and compliance management",
    ],

    image: img5,
    imgstyle: { marginTop: "-18px", marginRight: "19px", marginBottom: "11px" },
    knowmorebtn: "solutions/processrisks",
    textSection:{},
    cardContainerStyle:{},
    knowbtn:false
  },
];

const cardData1250 = [
  {
    headingContent: {
      title: "Operational Risk Change Management",
      subtitle:
        "Identify, mitigate, and monitor risks associated with planned policies, processes, products, or regulatory changes",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "Collaborative workflow with in-app discussion boards and meetings",
      "Actionable dashboard with real-time visibility",
      "Pre-built templates help streamline risk assessment",
      "Automated checks and controls and conditional sign-offs",
      "Ready-to-digest reports for auditors and regulators",
    ],

    image: img10241,
    imgstyle: {  marginBottom: "108px" ,width:"576px",height:"372px"},
    knowmorebtn: "solutions/operationalrisk",
    textSection:{},
    cardContainerStyle:{},
    knowbtn:false
  },
  {
    headingContent: {
      title: "Business Continuity Management",
      subtitle:
        "Initiate business impact analysis, develop robust recovery plans, and conduct business continuity tests to respond effectively to disruptions and recover swiftly",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "In-App Issue Tracker helps manage multiple issues and track progress",
      "Actionable Dashboard and Notifications offer clear insights into overall BCP effectiveness and manage risk",
      "Drill Reports share insights and identify areas for improvement",
      "Planned and Unplanned Continuity Planning helps prepare for any eventuality",
      "Audit Trail enables maintaining records of BCM activities for compliance",
    ],

    image: img10242,
    imgstyle: { width:"609px",height:"436px",marginRight: "-10px", marginBottom: "46px" },
    knowmorebtn: "solutions/businesscontinuity",
    textSection:{},
    cardContainerStyle:{},
    knowbtn:false
  },
  {
    headingContent: {
      title: "Outsourcing Risk Management",
      subtitle:
        "Streamline onboarding, simplify auditing, enhance risk assessment, centralize management, and reduce risk to strengthen relationships with vendors, regulatory compliance, and risk management throughout the entire vendor lifecycle",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "Automated workflow improves the onboarding process and automates data collection with pre-built templates, document uploads, and background checks",
      "Risk Assessment and Monitoring periodically tracks vendor activities and regulatory changes to identify emerging risks and maintain updated profiles",
      "Actionable Dashboard offers real-time data for informed decision-making and a shared view of risk",
      "In-app Discussion Boards and Meetings offer a secure collaborative environment",
      "Follow-up Checks and Controls send automated reminders for completing tasks",
    ],

    image: img10243,
    imgstyle: { height:"412px",width:"524px",marginRight: "13px", marginBottom: "49px" },
    knowmorebtn: "solutions/riskmanagement",
    textSection:{},
    cardContainerStyle:{},
    knowbtn:false
  },
  {
    headingContent: {
      title: "AI Governance and Risk Management",
      subtitle:
        "Design AI policy and guardrails for responsible AI development and use. Map and monitor the risks of AI usage across applications. Monitor AI models and use Explainability Trail across teams to improve transparency and trust",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "Collaborative Environment with In-App Discussion Board & Meetings help standardize governance and break down silos",
      "Actionable Dashboard and Notifications provide real-time insights, customizable views, and take timely actions",
      "Follow-up Checks and Controls promote continuous monitoring and early detection of issues",
      "Link Business Outcomes with Explainable Algorithms to help understand the model’s impact, ensure regulatory compliance, and build trust",
    ],

    image: img10244,
    imgstyle: { height:"531px",width:"551px",marginRight: "0px", marginBottom: "0px" },
    knowmorebtn: "solutions/aigovernance",
    textSection:{},
    cardContainerStyle:{},
    knowbtn:false
  },
  {
    headingContent: {
      title: "Process Risks & Controls Management",
      subtitle:
        "Catalog new processes in the library by obtaining approval and mapping them with risks and controls. Thus, you can have reusable processes and build a process family",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "A Collaborative Environment with an In-App Discussion Board & Meetings module helps centralize knowledge, identify risks, and streamline control testing",
      "Actionable Dashboard and Notifications offer real-time control test score and reminders to control owners",
      "Templates for Processes, Risks, and Controls ensure ease in design and documentation",
      "Control Testing and Risk Register help proactively identify, assess, and mitigate process risks",
      "Map process repository to other GRC applications and streamline data flow to help in risk identification and compliance management",
    ],

    image: img10245,
    imgstyle: { height:"492px",width:"499px", marginBottom:"1px",marginRight: "22px" },
    knowmorebtn: "solutions/processrisks",
    textSection:{},
    cardContainerStyle:{},
    knowbtn:false
  },
];

const cardData360 = [
  {
    headingContent: {
      title: "Operational Risk Change Management",
      subtitle:
        "Identify, mitigate, and monitor risks associated with planned policies, processes, products, or regulatory changes",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "Collaborative workflow with in-app discussion boards and meetings",
      "Actionable dashboard with real-time visibility",
      "Pre-built templates help streamline risk assessment",
      "Automated checks and controls and conditional sign-offs",
      "Ready-to-digest reports for auditors and regulators",
    ],

    image: img10241,
    imgstyle: { width:"296px",height:"200px",marginTop:"24px",marginLeft:"16px"},
    knowmorebtn: "solutions/operationalrisk",
    textSection:{height:"292px"},
    cardContainerStyle:{height:"564px"},
    knowbtn:false
  },
  {
    headingContent: {
      title: "Business Continuity Management",
      subtitle:
        "Initiate business impact analysis, develop robust recovery plans, and conduct business continuity tests to respond effectively to disruptions and recover swiftly",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "In-App Issue Tracker helps manage multiple issues and track progress",
      "Actionable Dashboard and Notifications offer clear insights into overall BCP effectiveness and manage risk",
      "Drill Reports share insights and identify areas for improvement",
      "Planned and Unplanned Continuity Planning helps prepare for any eventuality",
      "Audit Trail enables maintaining records of BCM activities for compliance",
    ],

    image: img10242,
    imgstyle: { width:"296px",height:"200px",marginTop:"24px",marginLeft:"16px" },
    knowmorebtn: "solutions/businesscontinuity",
    textSection:{height:"337px"},
    cardContainerStyle:{height:"609px"},
    knowbtn:false
  },
  {
    headingContent: {
      title: "Outsourcing Risk Management",
      subtitle:
        "Streamline onboarding, simplify auditing, enhance risk assessment, centralize management, and reduce risk to strengthen relationships with vendors, regulatory compliance, and risk management throughout the entire vendor lifecycle",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "Automated workflow improves the onboarding process and automates data collection with pre-built templates, document uploads, and background checks",
      "Risk Assessment and Monitoring periodically tracks vendor activities and regulatory changes to identify emerging risks and maintain updated profiles",
      "Actionable Dashboard offers real-time data for informed decision-making and a shared view of risk",
      "In-app Discussion Boards and Meetings offer a secure collaborative environment",
      "Follow-up Checks and Controls send automated reminders for completing tasks",
    ],

    image: img10243,
    imgstyle: { height:"221px",width:"296px",marginTop:"24px",marginLeft:"16px"},
    knowmorebtn: "solutions/riskmanagement",
    textSection:{height:"397px"},
    cardContainerStyle:{height:"690px"},
    knowbtn:false
  },
  {
    headingContent: {
      title: "AI Governance and Risk Management",
      subtitle:
        "Design AI policy and guardrails for responsible AI development and use. Map and monitor the risks of AI usage across applications. Monitor AI models and use Explainability Trail across teams to improve transparency and trust",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "Collaborative Environment with In-App Discussion Board & Meetings help standardize governance and break down silos",
      "Actionable Dashboard and Notifications provide real-time insights, customizable views, and take timely actions",
      "Follow-up Checks and Controls promote continuous monitoring and early detection of issues",
      "Link Business Outcomes with Explainable Algorithms to help understand the model’s impact, ensure regulatory compliance, and build trust",
    ],

    image: img10244,
    imgstyle: { height:"200px",width:"296px" ,marginTop:"24px",marginLeft:"16px"},
    knowmorebtn: "solutions/aigovernance",
    textSection:{height:"366px"},
    cardContainerStyle:{height:"638px"},
    knowbtn:true
  },
  {
    headingContent: {
      title: "Process Risks & Controls Management",
      subtitle:
        "Catalog new processes in the library by obtaining approval and mapping them with risks and controls. Thus, you can have reusable processes and build a process family",
    },
    subheading: "Top Features",
    subheadingscontent: [
      "A Collaborative Environment with an In-App Discussion Board & Meetings module helps centralize knowledge, identify risks, and streamline control testing",
      "Actionable Dashboard and Notifications offer real-time control test score and reminders to control owners",
      "Templates for Processes, Risks, and Controls ensure ease in design and documentation",
      "Control Testing and Risk Register help proactively identify, assess, and mitigate process risks",
      "Map process repository to other GRC applications and streamline data flow to help in risk identification and compliance management",
    ],

    image: img10245,
    imgstyle: { height:"200px",width:"296px",marginTop:"24px",marginLeft:"16px"},
    knowmorebtn: "solutions/processrisks",
    textSection:{height:"370px"},
    cardContainerStyle:{height:"642px"},
    knowbtn:true
  },
];
const OurSolutions = () => {
  
  const [cardsData, setCardsData] = useState( window.innerWidth <= 1200 ? window.innerWidth<=800 ? cardData360 :cardData1250 : cardData);
  useEffect(() => {
    const handleResize = () => {
      const newData =
      window.innerWidth <= 1200 ? window.innerWidth<=800 ? cardData360 :cardData1250 : cardData;
      setCardsData(newData);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="oursolution-container">
      <div className="ourtext-container">
        <div className="titleour">{data.title}</div>
        <div className="oursubtitle">{data.subtitle}</div>
      </div>
      <div className="oursolutioncards">
        {cardsData.map((card, index) => (
          <OurSolutionCard
            key={index}
            headingContent={card.headingContent}
            subheading={card.subheading}
            subheadingsContent={card.subheadingscontent}
            image={card.image}
            imgStyle={card.imgstyle}
            knowmorebtn={card.knowmorebtn}
            textSection={card.textSection}
            cardContainerStyle={card.cardContainerStyle}
            knowbtn = {card.knowbtn}
          />
        ))}
      </div>
    </div>
  );
};
export default OurSolutions;
