import "./WhyChooseCard.css";
interface cardprops {
  heading: string;
  subheadings: string[];
  image: string;
}
const WhyChooseCard: React.FC<cardprops> = (props) => {
  const { heading, subheadings, image } = props;

  return (
    <div className="cardContainer">
      <div>
        <img src={image} alt="" className="cardimage"></img>
      </div>
      <div className="cardheading">{heading}</div>
      <ul className="subcontent">
        {subheadings.map((item, index) => (
          <li className="cardsubheading" key={index}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default WhyChooseCard;
