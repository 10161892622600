import React, { CSSProperties } from "react";

import "./soltextImgBanner.css";
import { useScrollContext } from "../../../../ScrollContext";
const TextImgBanner = ({
  mainContainerStyles,
  textContainerStyles,
  heading,
  img,
  children,
  imgcss,
}: {
  mainContainerStyles: CSSProperties;
  textContainerStyles: CSSProperties;
  heading: string;
  img: string;
  children?: React.ReactNode;
  imgcss?: CSSProperties;
}) => {
  const { scrollToForm } = useScrollContext();

  return (
    <section
      className="sol-textimgBanner-container"
      style={mainContainerStyles}
    >
      <div className="sol-textbanner-textcontainer" style={textContainerStyles}>
        <div className="sol-textbanner-heading">{heading}</div>
        <div className="sol-textbanner-text-child">{children}</div>
      </div>
      <button className="sol-textbanner-btn" onClick={scrollToForm}>
        Request Demo
      </button>
      <img src={img} alt="" className="sol-bannerimg" style={imgcss} />
    </section>
  );
};

export default TextImgBanner;
