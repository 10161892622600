import React, { useEffect, useState } from "react";
import Banner from "../../../Common/Banner/Banner";
import LogoBanner from "../../../Common/LogoBanner/LogoBanner";
import benefitcard1 from "./assets/benefitsol2card1.jpg";
import benefitcard2 from "./assets/benefitsol2card2.jpg";
import benefitcard3 from "./assets/benefitsol2card3.jpg";
import benefitcard4 from "./assets/benefitsol2card4.jpg";
import benefitcard5 from "./assets/benefitsol2card5.jpg";
import benefitcard6 from "./assets/benefitsol2card6.jpg";
import keycard1 from "./assets/keysol2card1.jpg";
import keycard2 from "./assets/keysol2card2.jpg";
import keycard3 from "./assets/keysol2card3.jpg";
import keycard4 from "./assets/keysol2card4.jpg";
import keycard5 from "./assets/keysol2card5.jpg";
import bannerbelowimg from "./assets/bannerbelowimg.jpg";
import BenefitLayout from "../Components/BenefitsCard/BenefitLayout";
import { Icardprops } from "../Components/BenefitsCard/OurValueCard";
import KeyLayout from "../Components/KeyFeature/KeyLayout";
import TextImgBanner from "../Components/TextImgBanner/TextImgBanner";
import bannerImg from "../Assets/solnBannerImg.svg";

const bannerData = {
  title:
    "Build a resilient business with EvoNext’s integrated business continuity management solution",
  subtitle:
    "Plan for contingency measures against business disruptions and meet regulatory compliance with EvoNext’s powerful Business Continuity Management Solution. Designed by our team of BCM experts, we offer end-to-end solutions, from identifying risks to developing recovery plans and more. Our collaborative and secure solution is highly customizable to meet your business goals. \n\nIntegrated seamlessly with our suite of GRC services, which includes risk management and compliance, our global solution empowers organizations of all sizes and industries to build resilience.",
  image1: bannerImg,
  imgcss: { right: "0" },
  buttontext: "Request Demo",
  button: true,
  buttoncss: "requestdemobtn",
  textcss: { width: "clamp(18.5rem, -24.1138rem + 88.6633vw, 51.25rem)" },
  imgClass: "right",
};

const benefitcardData: Icardprops[] = [
  {
    heading: "Enhanced Preparedness",
    subheadings:
      "Identify risks, develop recovery plans, and centralize all data with our solution to ensure disruption readiness and recovery speed.",
    image: benefitcard1,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
  {
    heading: "Improved Response",
    subheadings:
      "Our features help streamline response procedures, facilitate communication, and minimize downtime to increase business resilience.",
    image: benefitcard2,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
  {
    heading: "Stronger Regulatory Compliance",
    subheadings:
      "Our audit trail feature helps maintain a record of BCM activities for regulatory requirements.",
    image: benefitcard3,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
  {
    heading: "Improved Decision-Making",
    subheadings:
      "Our structured approach helps identify critical functions, while risk assessment tools help quantify the potential impact of disruptions.",
    image: benefitcard4,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
  {
    heading: "Fostered Collaboration",
    subheadings:
      "Break down silos and ensure all stakeholders work together effectively with our collaborative dashboards, issue tracker and discussion boards.",
    image: benefitcard5,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
  {
    heading: "Simplified BCM Program Management",
    subheadings:
      "Automate tasks, streamline workflows, and gain a central view with our BCM.",
    image: benefitcard6,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
];
const KeyFeatureData: Icardprops[] = [
  {
    heading: "In-App Issue Tracker",
    subheadings:
      "Report, track, and manage issues with ease using our issue-tracking feature. The tracker acts as a central hub for all reported issues. These can then be filtered and routed to ensure that critical issues get prioritized. Teams can track their progress in real time and collaborate to provide a faster resolution.",
    image: keycard1,
    cardcontainercss: {
      height: "352px",
    },
  },
  {
    heading: "Actionable Dashboard",
    subheadings:
      "Monitor the health and preparedness of your Business Continuity Management (BCM) program with our Dashboard. It provides a clear view of critical information and highlights key issues, helping you make informed decisions fast. It fosters transparency by providing a shared view of data for relevant stakeholders and thus helps get a joint response on identified issues. ",
    image: keycard2,
    cardcontainercss: {
      height: "352px",
    },
  },
  {
    heading: "Drill Reports",
    subheadings:
      "Create powerful drill reports to document your BCM program's performance during simulated disruptions. We offer automated report generation based on pre-defined and customizable templates. By analyzing their findings, you can identify areas for improvement and ensure preparedness. Our solution provides insights to improve disaster recovery plans.",
    image: keycard3,
    cardcontainercss: {
      height: "352px",
    },
  },
  {
    heading: "Planned and Unplanned Continuity Planning",
    subheadings:
      "Streamline with a unified platform for all your continuity plans. It helps identify potential threats and develop strategies to mitigate the impact of disruption with risk assessment, BIA, recovery plan development, and testing and training. It thus helps you react quickly and effectively to any expected or unexpected disruption, making your organization more resilient.",
    image: keycard4,
    cardcontainercss: {
      height: "352px",
    },
  },
  {
    heading: "Audit Trail",
    subheadings:
      "The audit trail feature offers a clear, detailed activity log that tracks everything done—from creating plans to submitting reports. It serves as verifiable records for demonstrating your organization's commitment to BCM compliance and smoother audits. Leverage it to catch suspicious activity and strengthen security. It also facilitates risk management by ensuring authorized modifications to BCM plans. ",
    image: keycard5,
    cardcontainercss: {
      height: "352px",
    },
  },
];

const ImgTextBanner = () => {
  return (
    <div >
      Outdated Business Continuity Management (BCM) solutions and manual BCM
      processes can leave your organization vulnerable.  <br /> <br />
      At EvoNext, we've built a better way with workflow automation, data-driven
      insights, streamlined communication, and a centralized data repository. We
      have structured the entire BCM process -
      <ul>
        <li>
          Identifying and assessing the potential impact of disruptions with
          Business Impact Analysis (BIA)
        </li>
        <li>Identifying risks and scenario planning</li>
        <li>Developing Functional Recovery Plans (FRPs)</li>
        <li>
          Developing Business Continuity Plans (BCPs), planned and unplanned
        </li>
        <li>Monitoring impact</li>
      </ul>
      <br />
      Discover how our cost-effective and customizable BCM solution can help you
      weather any disruption or disaster.
    </div>
  );
};
const imgTextBannerData = {
  mainContainerStyles: {
    width: "100%",
    height: "clamp(28rem, 19.6851rem + 12.9794vw, 30.75rem)",
  },
  textContainerStyles: {
    width: "clamp(29.75rem, 7.8289rem + 34.2183vw, 37rem)",
    height: "clamp(24.25rem, 18.2028rem + 9.4395vw, 26.25rem)",
  },
  heading: "Integrated & collaborative business continuity management solution",
  img: bannerbelowimg,
  imgcss: {
    width: "clamp(29.9375rem, -2.9441rem + 51.3274vw, 40.8125rem)",
    height: "clamp(22rem, -2.1888rem + 37.7581vw, 30rem)",
  },
};

const imgTextBannerData360 = {
  mainContainerStyles: {
    width: "328px",
    marginLeft:"auto",
    marginRight:"auto",
    height: "703px",
  },
  textContainerStyles: {
    width: "328px",
    height: "382px",
  },
  heading: "Integrated & collaborative business continuity management solution",
  img: bannerbelowimg,
  imgcss: {
    width: "clamp(29.9375rem, -2.9441rem + 51.3274vw, 40.8125rem)",
    height: "clamp(22rem, -2.1888rem + 37.7581vw, 30rem)",
  },
};

const BusinessContinuity = () => {
  const [textimagebannerdata, setCardsData] = useState( window.innerWidth<=800 ? imgTextBannerData360 : imgTextBannerData);
  useEffect(() => {
    const handleResize = () => {
      const newData =
      window.innerWidth<=800 ? imgTextBannerData360 : imgTextBannerData;
      setCardsData(newData);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section>
      <div className="aboutusbannerimg">
        <Banner data={bannerData} />
      </div>
      <div className="adjustment-container">
        <TextImgBanner
          mainContainerStyles={textimagebannerdata.mainContainerStyles}
          textContainerStyles={textimagebannerdata.textContainerStyles}
          heading={textimagebannerdata.heading}
          img={textimagebannerdata.img}
          imgcss={textimagebannerdata.imgcss}
        >
          <ImgTextBanner />
        </TextImgBanner>
      </div>
      <KeyLayout data={KeyFeatureData} />
      <BenefitLayout data={benefitcardData} />
      <div className="logo-bannerabout">
        <LogoBanner
          text={
            "Reduce risk and meet regulations with our robust business continuity management solution platform. Schedule a demo today!"
          }
        />
      </div>
    </section>
  );
};

export default BusinessContinuity;
