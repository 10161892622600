import React from "react";
import "./FindOutHelpForm.css";
import { useState } from "react";
import HelpForm from "./helpForm/HelpForm";
import AlertNotification from "../../../../Common/AlertNotification/AlertNotification";
import SuccessPopup from "../SuccessPopup/SuccessPopup";
import CustomPopUp from "../../../../Common/CustomPopup/CustomPopUp";
let timer: any = 0;

function FindOutHelpForm() {
  const [
    isFormSuccessFullFlagToOpenPopUp,
    setIsFormSuccessfullFlagToOpenPopUp,
  ] = useState(false);

  const onSubmitForm = () => {
    setIsFormSuccessfullFlagToOpenPopUp(true);

    // if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      setIsFormSuccessfullFlagToOpenPopUp(false);
      clearTimeout(timer);
      window.scrollTo({top: 0,behavior: 'smooth', })
    }, 2000);
  };

  const onClosePopUp = () => {
    setIsFormSuccessfullFlagToOpenPopUp(false);
    window.scrollTo({top: 0,behavior: 'smooth', })
  };

  return (
    <>
      {/* <AlertNotification
        message={
          "Your form is submitted successfully! We will be in touch and contact you soon."
        }
        type="success"
        visible={isFormSuccessFull}
      /> */}
      <CustomPopUp
        open={isFormSuccessFullFlagToOpenPopUp}
        onCloseFunction={onClosePopUp}
      >
        <SuccessPopup onClose={onClosePopUp} />
      </CustomPopUp>

      <div className="FindOutHelpForm-main-container">
        <div className="FindOutHelpForm-content">
          Find Out How Our RegTech Solutions Can Help
        </div>
        <div className="FindOutHelpForm-form">
          <HelpForm onSubmitForm={onSubmitForm} />
        </div>
      </div>
    </>
  );
}

export default FindOutHelpForm;
