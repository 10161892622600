import React from "react";
import "./Home.css";
import HomeBanner from "./Components/HomeBanner";
import WhyChooseUs from "./Components/WhyChooseUs";
import RegTech from "./Components/Regtech/RegTech";
import OurSolutions from "./Components/OurSolutions/OurSolutions";
import FindOutHelpForm from "./Components/FindOutHelpForm/FindOutHelpForm";
import SlideShow from "./Components/SlideShow/SlideShow";
import ChooseEvoNext from "./Components/ChooseEvoNext/ChooseEvoNext";
import { useScrollContext } from "../../ScrollContext";

const Home = () => {
  const { homeFormRef } = useScrollContext();
  return (
    <div className="HomePageContainer">
      <HomeBanner />
      <SlideShow />
      <WhyChooseUs />
      <OurSolutions />
      <RegTech />
      <div ref={homeFormRef}></div>
      <FindOutHelpForm />
      <ChooseEvoNext />
    </div>
  );
};

export default Home;
