import "./whatmakesuscard.css";

interface Idata {
  img: string;
  title: string;
  description: string;
}

const WhatMakesUSdiffCard = ({ data }: { data: Idata }) => {
  const { img, title, description } = data;
  return (
    <div className="whatmakesuscard">
      <img src={img} className="cardimg" alt="" />
      <div className="whatmakesuscard-title">{title}</div>
      <div className="whatmakesuscard-description">{description}</div>
    </div>
  );
};

export default WhatMakesUSdiffCard;
