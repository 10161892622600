import { useNavigate } from "react-router-dom";
import "./OurSolutionCard.css";
interface cardprops {
  headingContent: {
    title: string;
    subtitle: string;
  };
  subheading: string;
  subheadingsContent: string[];
  image: string;
  imgStyle: {};
  knowmorebtn: string;
  textSection?:{};
  cardContainerStyle?:{}
  knowbtn?:boolean
}
const OurSolutionCard: React.FC<cardprops> = (props) => {
  const {
    headingContent,
    subheading,
    subheadingsContent,
    image,
    imgStyle,
    knowmorebtn,
    textSection,
    cardContainerStyle,
    knowbtn
  } = props;
  const navigate = useNavigate();
  return (
    <div style={cardContainerStyle}className="ourcardContainer">
      <div style={textSection} className="textsection">
        <div className="ourcardheadingcontent">
          <div className="ourtitlecard">{headingContent.title}</div>
          <div className="oursubtitlecard">{headingContent.subtitle}</div>
        </div>
        <div className="textimageourcontainers">
          <div className="textourcontainer">
            <div className="subheadingtext">{subheading}</div>
            <ul className="oursubcontent">
              {subheadingsContent.map((item, index) => (
                <li className="ourcardsubheading"> {item}</li>
              ))}
            </ul>
            <div style={{ height: "122px", width: "48px" }}>
              <button
                className={knowbtn ? 'knowother' : 'knowmorebtn'}
                onClick={() => {
                  navigate(`/${knowmorebtn}`);
                }}
              >
                Know More
              </button>
            </div>
          </div>
        </div>
      </div>
      <div  className="ourcardimage">
        <img style={imgStyle} src={image} alt=""></img>
      </div>
    </div>
  );
};
export default OurSolutionCard;
