import React from "react";
import "./CareerJourney.css";
function CareerJourney() {
  return (
    <div className="container-fluid CareerJourney-main-container">
      <div>
        <div className="CareerJourney-header">
          <span style={{ color: "#0D0C54" }}>Start your</span>
          <span style={{ color: "#1F7AE0" }}> career journey</span>
          <span style={{ color: "#0D0C54" }}> at</span>
          <span style={{ color: "#1F7AE0" }}> EvoNext</span>
        </div>
        <div className="CareerJourney-content">
          We are always on the hunt for inspiring team members. Please reach out
          even if your role is not listed and we will get back to you. 
        </div>
      </div>
      <div className="CareerJourney-banner">
        <div className="CareerJourney-banner-content">
          The potential candidate can email <u>hr@depronto.co.uk</u> with their
          resume.
        </div>
      </div>
    </div>
  );
}

export default CareerJourney;
