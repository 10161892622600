import RegCard from "../../../../Common/RegCard/RegCard";
import "./regtech.css";
import img1 from "./assets/regcard1.jpg";
import img2 from "./assets/regcard2.jpg";
import img3 from "./assets/regcard3.jpg";
import img4 from "./assets/regcard4.jpg";
import img5 from "./assets/regcard5.jpg";
import img6 from "./assets/regcard6.jpg";
const data = {
  title: "RegTech platform to drive outcomes for every industry and function",
};
const cardData = [
  {
    heading: "Business Unit Leaders and Executives",
    subheadings:
      "Demonstrate effective risk management to stakeholders and make data-driven decisions with a holistic view",
    image: img1,
  },
  {
    heading: "Risk Managers",
    subheadings: "Simplify risk management and improve operational efficiency",
    image: img2,
  },
  {
    heading: "Compliance Officers",
    subheadings:
      "Simplify risk automation, gap identification, and demonstration of compliance adherence",
    image: img3,
  },
  {
    heading: "Audit Teams",
    subheadings:
      "Boost efficiency with automation, ready-to-use reports, and critical issues identification",
    image: img4,
  },
  {
    heading: "Finance Teams",
    subheadings:
      "Strengthen internal controls, comply with regulations, and manage financial risks",
    image: img5,
  },
  {
    heading: "IT Security",
    subheadings:
      "Identify and prioritize IT risks, demonstrate compliance, and implement security policies.",
    image: img6,
  },
];
const RegTech = () => {
  return (
    <div className="regtech-container">
      <div className="regtext-container">
        <div className="titlereg">{data.title}</div>
      </div>
      <div className="regcards">
        {cardData.map((card, index) => (
          <RegCard
            key={index}
            heading={card.heading}
            subheadings={card.subheadings}
            image={card.image}
          />
        ))}
      </div>
    </div>
  );
};
export default RegTech;
