import { Link, NavLink, useLocation } from "react-router-dom";
import "./Header.styles.css";
import downarrowwhite from "./assets/downarrowwhite.svg";
import dropdownarrowblue from "./assets/dropdownarrowBlue.svg";
import { useScrollContext } from "../../ScrollContext";
import SolutionDropdown from "./SolutionDropdown";
import { useEffect, useRef, useState } from "react";
import hamburger from "./assets/Hamburger.svg";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";

const Header = () => {
  const { scrollToForm } = useScrollContext();
  const location = useLocation();
  const solDropDownRef = useRef<HTMLDivElement>(null);
  const [openSolnDropDown, setOpenSolnDropDown] = useState(false);
  const [onSolnPage, setOnSolnPage] = useState(false);
  const divref = useRef<HTMLDivElement>(null);

  const [openMenu, SetOpenMenu] = useState(false);
  const handleMenuToggle = () => {
   
    SetOpenMenu(true);
  };
  const closeMenu = () => {
    SetOpenMenu(false);
  };

  useEffect(() => {
    if (location.pathname.startsWith("/solutions")) {
      setOnSolnPage(true);
    } else {
      setOnSolnPage(false);
    }
  }, [location]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
   
      if (
        solDropDownRef.current &&
        !solDropDownRef.current.contains(event.target as Node) &&
        divref.current &&
        !divref.current.contains(event.target as Node)
      ) {
        setOpenSolnDropDown(false);
        closeMenu();
      }
    };
    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <header className="header-container">
      <section className="header-content">
        <aside className="header-logo">EvoNext</aside>
        <nav className="header-links">
          <NavLink
            to={"/home"}
            className={"header-link"}
            style={{
              width: "clamp(3.8125rem, 0.411rem + 5.3097vw, 4.9375rem)",
            }}
          >
            Home
          </NavLink>
          <div
            ref={divref}
            style={{ padding: "0px" }}
            className={"header-link"}
            onClick={(e) => setOpenSolnDropDown((prev) => !prev)}
          >
            <div
              className="SolContainer"
              style={{
                background: onSolnPage ? "white" : "transparent",
                color: onSolnPage ? "#1F7AE0" : "white",
                width: 126,
              }}
            >
              <div className="solutionNavHeading">
                <span>Solutions</span>
                <img
                  src={onSolnPage ? dropdownarrowblue : downarrowwhite}
                  alt=""
                  style={{ marginBottom: 4 }}
                  className="solndropdown-icon"
                />
              </div>
              {openSolnDropDown && (
                <div className="soln-dropdown-container" ref={solDropDownRef}>
                  <SolutionDropdown />
                </div>
              )}
            </div>
          </div>
          <NavLink
            to={"/about"}
            className={"header-link"}
            style={{ width: "clamp(5.25rem, 1.0926rem + 6.4897vw, 6.625rem)" }}
          >
            About Us
          </NavLink>
          <NavLink
            to={"/careers"}
            className={"header-link"}
            style={{
              width: "clamp(4.5625rem, 0.783rem + 5.8997vw, 5.8125rem)",
            }}
          >
            Careers
          </NavLink>
          <NavLink
            to={"/contact"}
            className={"header-link"}
            style={{ width: "clamp(6rem, 1.6536rem + 6.7847vw, 7.4375rem)" }}
          >
            Contact Us
          </NavLink>

          <div
            className={"req-demo-header"}
            style={{ width: 139 }}
            onClick={scrollToForm}
          >
            Request Demo
          </div>
        </nav>
        <img
          src={hamburger}
          alt=""
          height={24}
          onClick={handleMenuToggle}
          className="hamburger"
         
        />
        <div
         className="hamburgerMenuPosition" ref={solDropDownRef}>
          {openMenu && <HamburgerMenu closeMenu={closeMenu} />}
        </div>
      </section>
    </header>
  );
};

export default Header;
