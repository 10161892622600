import { useEffect, useState } from "react";
import map from "../../assets/map.svg";
import map360 from "../../assets/worldmap360.svg";
import markLoc from "../../assets/markLoc.svg";
import "./locatiomap.styles.css";
import LocCard, { Idata } from "./LocCard";
import { spawn } from "child_process";

const londonAddressData: Idata = {
  address: ["EvoNext Ltd.", "Harrow, London", "United Kingdom"],
  no: "+44 7732442498",
  mail: "sales@depronto.co.uk",
  containerStyle:{
    width:"clamp(8.8125rem, -2.6199rem + 23.8176vw, 17.625rem)"
  }
};
const indiaAddressData: Idata = {
  address: ["DePronto Infotech", "Surat, Mumbai", "India"],
  no: "+91 9920800008",
  mail: "gaurav.kabra@deprontoinfotech.com",
  containerStyle: {
    width: "clamp(13.75rem, 3.8285rem + 20.643vw, 21.375rem)",
  },
};

const LocMap = () => {
  const [mapData, setCardsData] = useState(  window.innerWidth<=800 ? map360 : map);
  useEffect(() => {
    const handleResize = () => {
      const newData =
      window.innerWidth<=800 ?map360 : map;
      setCardsData(newData);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [showLondonLocation, setShowLondonLocation] = useState<boolean>(false);
  const [showIndiaLocation, setShowIndiaLocation] = useState<boolean>(false);

  return (
    <section className="location-map-container">
      <div>
        <img src={mapData} alt="" className="loc-map" />
      </div>
      <div
        className="loation-mark-1"
        onMouseEnter={() => setShowLondonLocation(true)}
        onMouseLeave={() => {
          setShowLondonLocation(false);
        }}
      >
        <div className="loc-box-container">
          {showLondonLocation && (
            <span className="loccard-position1">
              <LocCard data={londonAddressData} />
            </span>
          )}
          <img src={markLoc} alt="" className="loc-box-container-icon" />
        </div>
      </div>
      <div
        className="loation-mark-2"
        onMouseEnter={() => setShowIndiaLocation(true)}
        onMouseLeave={() => {
          setShowIndiaLocation(false);
        }}
      >
        <div className="loc-box-container">
          {showIndiaLocation && (
            <span className="loccard-position2">
              <LocCard data={indiaAddressData} />
            </span>
          )}
          <img src={markLoc} alt="" className="loc-box-container-icon" />
        </div>
      </div>
    </section>
  );
};

export default LocMap;
