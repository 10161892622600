import React, { useEffect, useState } from 'react'
import bannerImg from './CareerBanner/CareerOpportunitiesBanner1.svg'
import SlideCard from '../../../../Common/careerOppCarrousel/SlideCard'
import img1024 from "./CareerBanner/carreropp1024.jpg";
import "./CareerOpprtunities.css"
function CareerOpprtunities() {

  const [bannerimg, setBannerimg] = useState( window.innerWidth <= 1250 ? img1024 : bannerImg);
  useEffect(() => {
    const handleResize = () => {
      const newData =
      window.innerWidth <= 1250 ?  img1024 :  bannerImg;
      setBannerimg(newData);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="container-fluid slidecard-main-container">
      <p className="careerOpprtunities-heading">Career Opportunities</p>
      <div className="careerOpprtunities-container">
      
        <img src={bannerimg} alt="" className="img-fluid carrerimg" />
      
        <div className="grid-item2">
          <SlideCard />
        </div>
      </div>
    </div>
  )
}

export default CareerOpprtunities