import formsuccess from "../../assets/formsuccess.svg";
import close from "../../assets/close.svg";
import "./successpopup.css";

const SuccessPopup = ({ onClose }: { onClose: () => void }) => {
  return (
    <main className="successPopupContainer">
      <div className="successPopUp-close" onClick={onClose}>
        <img src={close} alt="close" />
      </div>
      <section className="successPopupContent">
        <img className="successimg" src={formsuccess} alt="success" />
        <h3 className="successpopup-text">
          Your form is submitted successfully! We will be in touch and contact
          you soon.
        </h3>
      </section>
    </main>
  );
};

export default SuccessPopup;
