import { useNavigate } from "react-router-dom";
import { useScrollContext } from "../../ScrollContext";
import logo from "./assets/logo.svg";
import "./Logobanner.css";

const LogoBanner = ({ text }: { text: string }) => {
  const navigate = useNavigate();
  return (
    <section className="lb-logoBanner">
      <div className="lb-logoBanner-content">
        <div className="lb-logobannertext">{text} </div>
        <button
          className="lb-logobannerBtn"
          onClick={() => {
            navigate(`/contact`);
          }}
        >
          Get in Touch
        </button>
      </div>
      <img src={logo} alt="" className="logobanner-img" />
    </section>
  );
};

export default LogoBanner;
