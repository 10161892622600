// @ts-nocheck

import { NavLink } from "react-router-dom";
import LINKS from "./Links.json";
import { useState } from "react";
import "./Hamburgermenu.css";
import close from "../assets/close.svg";
import arrowup from "../assets/arrowup.svg";
import arrowdown from "../assets/arrowdown.svg";

const Accordian = ({ links,closeMenu }: { links: any ,closeMenu: () => void;}) => {
  const [AccordianMenuOpen, setAccordianMenuOpen] = useState(false);
  const handleclick=()=>{
    closeMenu();
  }
  const currentUrl = window.location.href;
  const flag = currentUrl.includes('/solutions');

  return (
    <section style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
      <div
        className="solutionlink"
        onClick={() => setAccordianMenuOpen(!AccordianMenuOpen)}
      >
        {flag ? (
          <p style={{ color: "#1F7AE0" }} className="hamburgerLink">
            {links.label}
          </p>
        ) : (
          <p className="hamburgerLink">{links.label}</p>
        )}

        {AccordianMenuOpen ? (
          <img className="arrow" src={arrowdown} alt="" />
        ) : (
          <img className="arrow" src={arrowup} alt="" />
        )}
      </div>
      {AccordianMenuOpen && (
        <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
          {links.children.map((link, i) => (
            <NavLink
              to={link.link as string}
              key={i}
              className="hamburgerChildLink"
              onClick={handleclick}
            >
              {link.label}
            </NavLink>
          ))}
        </div>
      )}
    </section>
  );
};

const HamburgerMenu = ({ closeMenu }) => {
 
  return (
    <section className="hamburgerContainer">
      <div className="HamburgerHeader">
        <img src={close} alt="" onClick={closeMenu} />
      </div>
      <div className="hamburgerLinkContainer">
        {LINKS.map((link, i) => {
          return link.hasChildren ? (
            <div key={i}>
              <Accordian links={link} closeMenu={closeMenu} />
            </div>
          ) : (
            <NavLink to={link.link as string} key={i} className="hamburgerLink"  onClick={closeMenu}>
              {link.label}
            </NavLink>
          );
        })}
      </div>
    </section>
  );
};

export default HamburgerMenu;
