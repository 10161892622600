import { Icardprops } from "../BenefitsCard/OurValueCard";
import KeyFeatureCard from "./KeyFeatureCard";
import "./KeyLayout.css";

interface KeyLayoutProps {
  data: Icardprops[];
}
const KeyLayout = ({ data }: KeyLayoutProps) => {
  return (
    <section className="key-main-container">
      <div className="headingkey">Key Feature</div>
      <div className="keycardcontainer">
        {data.map((card) => (
          <KeyFeatureCard data={card} />
        ))}
      </div>
    </section>
  );
};
export default KeyLayout;
