import "./regCard.css";
interface cardprops {
  heading: string;
  subheadings: string;
  image: string;
}
const RegCard: React.FC<cardprops> = (props) => {
  const { heading, subheadings, image } = props;

  return (
    <div className="regcardContainer">
      <div>
        <img src={image} alt="" className="regcardimage"></img>
      </div>
      <div className="regtext">
        <div className="regcardheading">{heading}</div>
        <div className="regsubcontent">{subheadings}</div>
      </div>
    </div>
  );
};
export default RegCard;
