import "./SlideShowCard.css";

export interface Idata {
  heading: string;
  content: string;
  image: string;
}

const SlideShowCard = ({ data }: { data: Idata }) => {
  const { heading, content, image } = data;
  return (
    <section className="slideshow-card-container">
      <img src={image} className="imgslider" alt="" height={180} width={324} />
      <span className="slideShowCard-heading">{heading} </span>
      <span className="slideShowCard-content">{content} </span>
    </section>
  );
};

export default SlideShowCard;
