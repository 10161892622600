import React, { useEffect, useState } from "react";
import Banner from "../../../Common/Banner/Banner";
import bannerbelowimg from "./assets/outsourcingriskbannerbelow.jpg";
import TextImgBanner from "../Components/TextImgBanner/TextImgBanner";
import KeyLayout from "../Components/KeyFeature/KeyLayout";
import { Icardprops } from "../Components/BenefitsCard/OurValueCard";
import keycard1 from "./assets/keysol3card1.jpg";
import keycard2 from "./assets/keysol3card2.jpg";
import keycard3 from "./assets/keysol3card3.jpg";
import keycard4 from "./assets/keysol3card4.jpg";
import keycard5 from "./assets/keysol3card5.jpg";
import benefitcard1 from "./assets/benefitsol3card1.jpg";
import benefitcard2 from "./assets/benefitsol3card2.jpg";
import benefitcard3 from "./assets/benefitsol3card3.jpg";
import benefitcard4 from "./assets/benefitsol3card4.jpg";
import benefitcard5 from "./assets/benefitsol3card5.jpg";
import benefitcard6 from "./assets/benefitsol3card6.jpg";
import BenefitLayout from "../Components/BenefitsCard/BenefitLayout";
import LogoBanner from "../../../Common/LogoBanner/LogoBanner";
import bannerImg from "../Assets/solnBannerImg.svg";

const bannerData = {
  title:
    "Go beyond compliance with EvoNext’s outsourcing risk management solution",
  subtitle:
    "Streamline third-party onboarding. Build stronger relationships. Proactively manage risks. Strengthen compliance. Achieve faster time-to-value. All this is possible with our outsourcing risk management solution. \n \nWe are a team of experts passionate about empowering businesses with comprehensive risk management solutions, including AI governance, business continuity management, and operational risk change management. Our all-in-one solution offers a wide range of integrations and robust security. We are loved by a global audience of all sizes and industries because of our collaborative and customizable approach, focusing on holistic risk management and building resilience for your organization.",
  image1: bannerImg,
  imgcss: { right: "0" },
  buttontext: "Request Demo",
  button: true,
  buttoncss: "requestdemobtn",
  textcss: { width: "clamp(18.5rem, -24.1138rem + 88.6633vw, 51.25rem)" },
  imgClass:"right"
};
const imgTextBannerData = {
  mainContainerStyles: { width: "100%", height: "clamp(24.0625rem, 3.4642rem + 32.1534vw, 30.875rem)" },
  textContainerStyles: { width: "clamp(29.75rem, 7.8289rem + 34.2183vw, 37rem)", height: "clamp(20.3125rem, 11.4307rem + 13.8643vw, 23.25rem)" },
  heading: "End-to-end outsourcing risk management solution",
  img: bannerbelowimg,
  imgcss:{
    width: "clamp(28.875rem, -17.0459rem + 71.6814vw, 44.0625rem)",
    height: "clamp(20.25rem, -11.8757rem + 50.1475vw, 30.875rem)",
    marginTop:"clamp(2.25rem, 9.0132rem + -10.5572vw, 0rem)"
  }
};
const imgTextBannerData360 = {
  mainContainerStyles: {  width: "328px",
    marginLeft:"auto",
    marginRight:"auto", height: "667px" },
  textContainerStyles: { width: "328px", height: "346px" },
  heading: "End-to-end outsourcing risk management solution",
  img: bannerbelowimg,
  imgcss:{
    width: "clamp(28.875rem, -17.0459rem + 71.6814vw, 44.0625rem)",
    height: "clamp(20.25rem, -11.8757rem + 50.1475vw, 30.875rem)",
   
  }
};


const ImgTextBanner = () => {
  return (
    <div>
      Ensuring compliance isn’t the biggest challenge that organizations face
      with vendor management. It’s a complex interplay of several factors,
      including limited visibility and control, difficulty maintaining positive
      relationships, and dependence on manual workflows. <br /> <br />
      EvoNext’s Outsourcing Risk Management Solution is designed to solve these
      and strengthen the ecosystem. Our solution makes this possible with faster
      vendor integration, workflow automation, analytics and reporting,
      continuous monitoring, streamlined communication, data centralization, and
      more such features. <br /> <br />
      Discover how our cost-effective and collaborative solution can improve
      your organization's efficiency and profitability.
    </div>
  );
};

const KeyFeatureData: Icardprops[] = [
  {
    heading: "Risk Assessment and Monitoring",
    subheadings:
      "Periodically track vendor activities and regulatory changes to identify emerging risks and maintain updated profiles. We offer questionnaire templates to send to vendors through automated workflows to collect data and address risk areas. Our solution can also help with risk assessment and monitoring by conducting periodic reassessments of vendors.",
    image: keycard1,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "Automated and Collaborative Workflow",
    subheadings:
      "Streamline onboarding and risk assessment by automating workflow involving repetitive tasks. Our collaborative platform facilitates real-time communication, automated reminders, and pre-defined templates to improve efficiency, vendor relationships, and transparency. It ensures clear ownership of tasks for risk mitigation.",
    image: keycard2,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "Actionable Dashboard",
    subheadings:
      "Our Dashboard centralizes all critical information about your vendors. It provides real-time insights, data-driven recommendations and actionable alerts, helping you identify potential risks and prioritize actions. You can also share information with stakeholders and facilitate collaborative risk management.",
    image: keycard3,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "Follow-Up Checks and Controls",
    subheadings:
      "They help ensure that conditional sign-off tasks and identified vendor risks are completed and aren't overlooked. The system centralizes the task list and provides real-time updates to improve visibility and tracking. We also enable the collection of evidence of completed checks and controls, creating a clear audit trail.",
    image: keycard4,
    cardcontainercss: { height: "352px" },
  },
  {
    heading: "In-App Discussion Boards and Meetings",
    subheadings:
      "We offer a secure and central platform to ask questions, share information, and discuss. Teams can identify risks together and brainstorm in meetings to mitigate risks, ensure transparency, and collaborate. Discussion boards help monitor progress, document risk discussions, and share insights, simplifying audits and demonstrating compliance.",
    image: keycard5,
    cardcontainercss: { height: "352px" },
  },
];

const benefitcardData: Icardprops[] = [
  {
    heading: "Improved Efficiency",
    subheadings:
      "Automate tasks and data entry, streamline workflows, and reduce potential disruptions from third-party issues with our economical solution.",
    image: benefitcard1,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
  {
    heading: "Increased Visibility",
    subheadings:
      "Leverage the dashboard to get a centralized view of all vendor risk data and the centralized data repository to improve transparency.",
    image: benefitcard2,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
  {
    heading: "Enhanced Risk Management",
    subheadings:
      "Risk assessment and monitoring and follow-up checks help with proactive identification, mitigation, and monitoring of potential risks.",
    image: benefitcard3,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
  {
    heading: "Strengthened Vendor Relationships",
    subheadings:
      "Foster collaboration and communication with our collaborative workflows and discussion boards.",
    image: benefitcard4,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
  {
    heading: "Regulatory Compliance",
    subheadings:
      "Audit trails and pre-built compliance templates help meet industry regulations and internal security policies.",
    image: benefitcard5,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
  {
    heading: "Improved Decision-Making",
    subheadings:
      "We offer data-driven insights to help make informed choices about vendors and risk strategies.",
    image: benefitcard6,
    cardcontainercss: {
      height: "clamp(7.125rem, 5.4985rem + 3.3841vw, 8.375rem)",
    },
  },
];
const RiskManagement = () => {
  const [textimagebannerdata, setCardsData] = useState( window.innerWidth<=800 ? imgTextBannerData360 : imgTextBannerData);
  useEffect(() => {
    const handleResize = () => {
      const newData =
      window.innerWidth<=800 ? imgTextBannerData360 : imgTextBannerData;
      setCardsData(newData);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section>
      <div className="aboutusbannerimg">
        <Banner data={bannerData} />
      </div>
      <div className="adjustment-container">
        <TextImgBanner
          mainContainerStyles={textimagebannerdata.mainContainerStyles}
          textContainerStyles={textimagebannerdata.textContainerStyles}
          heading={textimagebannerdata.heading}
          img={textimagebannerdata.img}
          imgcss={textimagebannerdata.imgcss}
        >
          <ImgTextBanner />
        </TextImgBanner>
      </div>
      <KeyLayout data={KeyFeatureData} />
      <BenefitLayout data={benefitcardData} />
      <div className="logo-bannerabout">
        <LogoBanner
          text={
            "Simplify risk management and achieve compliance with our collaborative outsourcing risk management solution."
          }
        />
      </div>
    </section>
  );
};

export default RiskManagement;
